import { useRouter } from "next/router";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { trackleadform } from "@/analytics/analytics";

import Box from "@/design-system/Box";
import UnstyledButton from "@/design-system/Button/UnstyledButton";
import Icon from "@/design-system/Icon";

import { useScreenTime } from "@/hooks/useScreenTime";

import { theme } from "@/theme";

import { progressBarConfig, submitButtonConfig } from "../constant";
import Thankyou from "../Thankyou";
import { FEEDBACK_QUESTION_TYPE, FeedbackFormPropType, FormMetaPropType } from "../type";

import FormBody from "./FormBody";
import FormFooter from "./FormFooter";
import FormHeader from "./FormHeader";

const StyledBox = styled(Box)`
  max-width: 600px;
  background-color: #dcdfea;
  border-radius: 12px;
`;
export const CloseButton = styled(UnstyledButton)`
  display: grid;
  place-items: center;
  position: absolute;
  right: 8px;
  width: 28px;
  z-index: 1;
  height: 28px;
  top: 8px;
  background-color: ${theme.colors.gray[800]};
  border-radius: 50%;
  @media (min-width: ${({ theme }) => theme.breakpoints.xs}) {
    right: 20px;
    top: -15px;
  }
`;

export default function FeedbackForm({
  handleClose,
  isBottomContainer = true,
  formJson,
  saveForm,
  notificationId,
  mixpanelObj,
  selectQuestionIndex,
  setSelectedQuestionIndex,
  cohortId,
}: FeedbackFormPropType) {
  const [payload, setPayload] = useState([]);
  const time = useScreenTime();
  const router = useRouter();
  useEffect(() => {
    if (payload.length === 1) {
      const length = formJson?.questions?.[0]?.options?.length;
      const newMixpanelObj = {
        on_screen_duration: time.getFormattedScreenTime(),
        ...mixpanelObj,
        question_content: formJson?.questions?.[0]?.question ?? null,
        question_tag: formJson?.questions?.[0]?.meta.questionTag ?? null,
        category: formJson?.questions?.[0]?.meta.category ?? null,
        sub_category: formJson?.questions?.[0]?.meta.subCategory ?? null,
        min_score: length > 0 ? formJson?.questions?.[0]?.options?.[0].optionId : null,
        max_score: length > 0 ? formJson?.questions?.[0]?.options?.[length - 1].optionId : null,
      };
      trackleadform("Form_start", newMixpanelObj);
    }
  }, [payload, mixpanelObj, formJson]);
  const isSubmitBtnVisible = useMemo(() => {
    return (
      formJson.questions?.[selectQuestionIndex]?.questionType ===
        FEEDBACK_QUESTION_TYPE.MULTI_CHOICE ||
      formJson.questions?.[selectQuestionIndex]?.questionType === FEEDBACK_QUESTION_TYPE.SUBJECTIVE
    );
  }, [formJson, selectQuestionIndex]);

  const modifiedFormJson = useMemo(() => {
    if (formJson.questions.length > 1) {
      if (isSubmitBtnVisible) {
        return {
          ...formJson,
          footer: {
            components: [
              ...formJson?.footer?.components.map((element: any) => {
                if (element.type === "PROGRESS_BAR") {
                  return {
                    ...element,
                    totalCount: formJson?.questions.length,
                    activeCount: selectQuestionIndex,
                  };
                }
                return element;
              }),
              submitButtonConfig,
            ],
          },
        };
      } else {
        return {
          ...formJson,
          footer: {
            components: [
              ...formJson?.footer?.components,
              {
                ...progressBarConfig,
                totalCount: formJson?.questions.length,
                activeCount: selectQuestionIndex + 1,
              },
            ],
          },
        };
      }
    }
    return formJson;
  }, [selectQuestionIndex, isSubmitBtnVisible]);

  const { header, footer, questions, submitContent, freezeAtLastAttempt } = modifiedFormJson || {};

  const lastStep = selectQuestionIndex === formJson?.questions?.length;

  const isAutoSubmitTrigger = useMemo(() => {
    const isSubjectiveOrMultiChoice = questions.find(
      (element) =>
        element.questionType === FEEDBACK_QUESTION_TYPE.MULTI_CHOICE ||
        element.questionType === FEEDBACK_QUESTION_TYPE.SUBJECTIVE
    );
    return !Boolean(isSubjectiveOrMultiChoice) || isBottomContainer;
  }, [questions, isBottomContainer]);

  useEffect(() => {
    window.onbeforeunload = () => {
      if (mixpanelObj && payload.length === 1) {
        trackleadform("Form_abondonment", mixpanelObj);
      }
    };
  }, [mixpanelObj, payload]);
  useEffect(() => {
    const handler = () => {
      if (mixpanelObj && payload.length === 1) trackleadform("Form_abondonment", mixpanelObj);
    };
    router.events.on("routeChangeStart", handler);
    return () => {
      router.events.off("routeChangeStart", handler);
    };
  }, [mixpanelObj, payload]);

  return (
    <StyledBox>
      {lastStep ? (
        <Thankyou
          submitContent={submitContent}
          isBottomContainer={isBottomContainer}
          handleClose={handleClose}
          mixpanelObj={mixpanelObj}
          payload={payload}
          saveForm={saveForm}
          notificationId={notificationId}
          cohortId={cohortId}
          isAutoSubmitTrigger={isAutoSubmitTrigger}
        />
      ) : (
        <>
          {header && <FormHeader direction={header.dir} components={header.components} />}
          <FormBody
            isBottomContainer={isBottomContainer}
            payload={payload}
            setPayload={setPayload}
            questions={questions}
            selectQuestionIndex={selectQuestionIndex}
            setSelectedQuestionIndex={setSelectedQuestionIndex}
            mixpanelObj={mixpanelObj}
          />
          {footer && (
            <FormFooter
              notificationId={notificationId}
              payload={payload}
              skipForm={saveForm}
              setSelectedQuestionIndex={setSelectedQuestionIndex}
              selectQuestionIndex={selectQuestionIndex}
              direction={(footer as FormMetaPropType).dir}
              components={footer.components}
              handleClose={handleClose}
              mixpanelObj={mixpanelObj}
            />
          )}
        </>
      )}
      {isBottomContainer && !freezeAtLastAttempt && (
        <CloseButton onClick={() => handleClose("cross")}>
          <Icon type="x" varient="line" color={theme.colors.base.white} />
        </CloseButton>
      )}
    </StyledBox>
  );
}
