import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getLearnerQuery } from "@/client/lmsLearnerQuery";

import { InternalQueryStateFilter } from "@/utils/types.enums";

import { QueryWithNamespace } from "@/client/types/queryApi.type";

interface QueryInitialState {
  isUserMentor: boolean;
  queryState: InternalQueryStateFilter;
  list: QueryWithNamespace[];
  data: QueryWithNamespace[];
  scrollDirection: "up" | "down";
}

const initialState: QueryInitialState = {
  queryState: InternalQueryStateFilter.ALL,
  list: [],
  data: [],
  isUserMentor: false,
  scrollDirection: "up",
};

const querySlice = createSlice({
  name: "querySlice",
  initialState,
  reducers: {
    changeScrollPosition: (state, action: PayloadAction<"up" | "down">) => {
      state.scrollDirection = action.payload;
    },
    changeQueryState(
      state,
      action: PayloadAction<{ isUserMentor: boolean; filter: InternalQueryStateFilter }>
    ) {
      const { isUserMentor, filter } = action.payload;
      state.queryState = filter;
      state.isUserMentor = isUserMentor;

      if (filter === InternalQueryStateFilter.RESOLVED) {
        state.list = state.data.filter((i) => i.state === filter);
      } else if (filter === InternalQueryStateFilter.OPEN) {
        state.list = state.data.filter((i) => i.state !== InternalQueryStateFilter.RESOLVED);
      } else {
        state.list = state.data;
      }
    },
    resetState(state) {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(getLearnerQuery.endpoints.getQueryList.matchFulfilled, (state, action) => {
      state.data = action.payload;

      if (state.queryState === InternalQueryStateFilter.RESOLVED) {
        state.list = state.data.filter((i) => i.state === state.queryState);
      } else if (state.queryState === InternalQueryStateFilter.OPEN) {
        state.list = state.data.filter((i) => i.state !== InternalQueryStateFilter.RESOLVED);
      } else {
        state.list = state.data;
      }
    });
  },
});

export const { changeQueryState, resetState, changeScrollPosition } = querySlice.actions;
export default querySlice.reducer;
