import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import { baseQueryApiClient } from "./client/BaseQueryApiClient";
import { careerBuilderClient } from "./client/careerBuilderClient";
import { groupPerformanceApi } from "./client/groupPerformanceApiClient";
import { getCourseContent } from "./client/lmsApiClient";
import { getIGEarning } from "./client/lmsIGEarningClient";
import { getLearnerQuery } from "./client/lmsLearnerQuery";
import { getTaskAssessmentApi } from "./client/lmsTaskAssessmentApiClient";
import { mentorPerformanceApi } from "./client/mentorPerformanceApiClient";
import { getMMSCourseContent } from "./client/mmsApiClient";
import { myApplicationClient } from "./client/myApplicationClient";
import { OnboardingForm } from "./client/OnBoardingClient";
import { placementHubClient } from "./client/placementHubClient";
import { getUserData } from "./client/userDataClient";
import { userProfileClient } from "./client/userProfileApiCLient";
import rootReducer from "./reducers";

const store = configureStore({
  reducer: {
    rootReducer,
    [placementHubClient.reducerPath]: placementHubClient.reducer,
    [careerBuilderClient.reducerPath]: careerBuilderClient.reducer,
    [userProfileClient.reducerPath]: userProfileClient.reducer,
    [getCourseContent.reducerPath]: getCourseContent.reducer,
    [baseQueryApiClient.reducerPath]: baseQueryApiClient.reducer,
    [myApplicationClient.reducerPath]: myApplicationClient.reducer,
    [OnboardingForm.reducerPath]: OnboardingForm.reducer,
    [getUserData.reducerPath]: getUserData.reducer,
    [getLearnerQuery.reducerPath]: getLearnerQuery.reducer,
    [getTaskAssessmentApi.reducerPath]: getTaskAssessmentApi.reducer,
    [mentorPerformanceApi.reducerPath]: mentorPerformanceApi.reducer,
    [groupPerformanceApi.reducerPath]: groupPerformanceApi.reducer,
    [getIGEarning.reducerPath]: getIGEarning.reducer,
    [getMMSCourseContent.reducerPath]: getMMSCourseContent.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      placementHubClient.middleware,
      userProfileClient.middleware,
      getCourseContent.middleware,
      careerBuilderClient.middleware,
      // getCapstoneContent.middleware,
      baseQueryApiClient.middleware,
      myApplicationClient.middleware,
      OnboardingForm.middleware,
      getUserData.middleware,
      getLearnerQuery.middleware,
      getTaskAssessmentApi.middleware,
      mentorPerformanceApi.middleware,
      groupPerformanceApi.middleware,
      getIGEarning.middleware,
      getMMSCourseContent.middleware,
    ]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppStore = typeof store;
export type AppDispatch = typeof store.dispatch;

setupListeners(store.dispatch);

export default store;

// import { configureStore } from "@reduxjs/toolkit";
// import rootReducer from "./reducers";

// const store = configureStore({ reducer: rootReducer });

// export type RootState = ReturnType<typeof store.getState>;
// export type AppDispatch = typeof store.dispatch;

// export default store;
