import styled, { css } from "styled-components";
import {
  background,
  border,
  flexbox,
  layout,
  position,
  space,
} from "styled-system";
import { FlexContainerProps } from "./types";

export const StyledFlexContainer = styled.div<FlexContainerProps>`
  display: flex;
  ${space}
  ${flexbox}
  ${border}
  ${position}
  ${layout}
  ${background}
  ${({ gap }) =>
    gap &&
    css`
      gap: ${gap};
    `}
`;
