import styled, { css } from "styled-components";
import { layout, space } from "styled-system";

import Button from "@/design-system-supernova/Button";

import { theme } from "@/theme";

import { CustomButtonProps } from "../button-varient";

export const PrimaryStyledButton = styled(Button)<CustomButtonProps>`
  ${space}
  ${layout}
  ${({ color, loading, rounded }) =>
    color === "purple" &&
    css`
      background-color: ${({ theme }) =>
    loading ? theme.colors.purple[400] : theme.colors.purple[800]};
      color: ${({ theme }) => theme.colors.base.white};
      border-radius: ${rounded ? "9999px" : theme.radius(2)};
      &:hover {
        background-color: ${({ theme }) => theme.colors.purple[600]};
      }
      &:active {
        background-color: ${({ theme }) => theme.colors.purple[900]};
      }
      &:disabled {
        background-color: ${({ theme }) => theme.colors.purple[200]};
      }
      ${loading &&
      css`
        cursor: default;
        &:hover {
          background-color: ${({ theme }) => theme.colors.purple[400]};
        }
      `}
    `}

  ${({ color, loading, rounded }) =>
    color === "neutral" &&
    css`
      background-color: ${({ theme }) =>
    loading ? theme.colors.grayCool[950] : theme.colors.grayCool[900]};
      color: ${({ theme }) => theme.colors.base.white};
      border-radius: ${rounded ? "9999px" : theme.radius(2)};
      &:hover {
        background-color: ${({ theme }) => theme.colors.grayCool[800]};
      }
      &:active {
        background-color: ${({ theme }) => theme.colors.grayCool[950]};
      }
      &:disabled {
        background-color: ${({ theme }) => theme.colors.grayCool[200]};
      }
      ${loading &&
      css`
        cursor: default;
        &:hover {
          background-color: ${({ theme }) => theme.colors.grayCool[900]};
        }
      `}
    `}

    ${({ color, rounded, loading }) =>
    color === "dark" &&
    css`
      color: ${({ theme }) => theme.colors.purple[800]};
      border-radius: ${rounded ? "9999px" : theme.radius(2)};
      background-color: ${({ theme }) => theme.colors.base.white};
      border: 1px solid ${theme.colors.purple[200]};
      &:hover {
        background-color: ${({ theme }) => theme.colors.purple[100]};
      }
      &:active {
        background-color: ${({ theme }) => theme.colors.purple[200]};
      }
      &:disabled {
        background-color: ${({ theme }) => theme.colors.grayCool[100]};
        color: ${({ theme }) => theme.colors.purple[300]};
        border: 1px solid ${theme.colors.grayCool[200]};
      }
      ${loading &&
      css`
        cursor: default;
        background-color: ${({ theme }) => theme.colors.grayCool[100]};
        border: 1px solid ${theme.colors.grayCool[200]};
      `}
    `}
      ${({ disableGutter }) =>
    disableGutter &&
    css`
      padding: 0;
      height: auto;
    `}
`;
