import Tabs from "@/design-system-v2/Tabs/Tabs";
import Box from "@/design-system/Box";

const NavigationMiddle = ({
  readonly,
  setActiveStep,
  maxActiveStep,
  activeStep,
}: any) => {
  return (
    <Box overflow={"auto"}>
      <Box display={"flex"}>
        <Tabs
          tabType="normal"
          setTabname={(e) => {
            if (Number(e) <= maxActiveStep) {
              !readonly && setActiveStep(Number(e));
            }
          }}
          activeTab={activeStep}
          elementList={[
            { tabKeyName: 0, content: "Fill Employment Details" },
            { tabKeyName: 1, content: "Choose EMI Plan" },
            { tabKeyName: 2, content: "Fill Personal Information" },
            { tabKeyName: 3, content: "Fill Contact Information" },
            { tabKeyName: 4, content: "Upload Documents" },
            { tabKeyName: 5, content: "Review Page" },
          ]}
        />
      </Box>
    </Box>
  );
};

export default NavigationMiddle;
