import Button from "@/design-system-v3/Button";
import { theme } from "@/theme";
import styled, { css } from "styled-components";
import { layout, space } from "styled-system";
import { PrimaryButtonProps } from "./types";

export const PrimaryStyledButton = styled(Button)<PrimaryButtonProps>`
  ${space}
  ${layout}
  ${({ color, loading }) =>
    color === "purple" &&
    css`
      background-color: ${({ theme }) =>
        loading ? theme.colors.purple[400] : theme.colors.purple[800]};
      color: ${({ theme }) => theme.colors.base.white};
      &:hover {
        background-color: ${({ theme }) => theme.colors.purple[600]};
      }
      &:active {
        background-color: ${({ theme }) => theme.colors.purple[900]};
      }
      &:disabled {
        background-color: ${({ theme }) => theme.colors.purple[200]};
      }
    `}

  ${({ color, loading }) =>
    color === "neutral" &&
    css`
      background-color: ${({ theme }) =>
        loading ? theme.colors.base.black : theme.colors.gray[800]};
      color: ${({ theme }) => theme.colors.base.white};

      &:hover {
        background-color: ${({ theme }) => theme.colors.base.black};
      }
      &:active {
        background-color: ${({ theme }) => theme.colors.grayCool[800]};
      }
      &:disabled {
        background-color: ${({ theme }) => theme.colors.grayCool[200]};
      }
    `}

    ${({ color }) =>
    color === "neutralDark" &&
    css`
      color: ${({ theme }) => theme.colors.base.black};
      background-color: ${({ theme }) => theme.colors.base.white};
      border: 1px solid ${theme.colors.gray[200]} !important;

      &:hover {
        background-color: ${({ theme }) => theme.colors.grayCool[100]};
      }
      &:active {
        background-color: ${({ theme }) => theme.colors.grayCool[200]};
      }
      &:disabled {
        background-color: ${({ theme }) => theme.colors.grayCool[100]};
        color: ${({ theme }) => theme.colors.grayCool[400]};
      }
    `}
      ${({ disableGutter }) =>
    disableGutter &&
    css`
      padding: 0;
      height: auto;
    `}
`;
