import Box from "@/design-system/Box";
import UnstyledButton from "@/design-system/Button/UnstyledButton";
import Icon from "@/design-system/Icon";
import { IconType, IconVarient } from "@/design-system/Icon/types";
import { theme } from "@/theme";
import { FC, useEffect, useState } from "react";
import Badge from "../Badge/badge";
import Typography from "../Typography";
import { TabObject, TabProps } from "./Tabs.type";

const Tabs: FC<TabProps> = ({
  elementList,
  setTabname,
  tabType,
  activeTab,
}) => {
  const [tabName, setTabName] = useState<string | number>("");

  const onTabClickHandler = (tabName: string | number) => {
    setTabName(tabName);
    setTabname(tabName);
  };

  useEffect(() => {
    if (activeTab != "-1") {
      setTabName(activeTab);
    }
  }, [activeTab]);

  return (
    <Box width={"100%"}>
      <Box
        display={"flex"}
        justifyContent={tabType === "chips" ? "start" : "space-between"}
      >
        {elementList.map((data: TabObject, index: number) => {
          return (
            <UnstyledButton
              onClick={() => onTabClickHandler(data.tabKeyName)}
              key={index}
            >
              {tabType === "normal" ? (
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  borderBottom={
                    data.tabKeyName === tabName
                      ? `3px solid ${theme.colors.primary[600]}`
                      : `3px solid ${theme.colors.gray[300]}`
                  }
                  px={theme.spacing(2)}
                  py={theme.spacing(2.5)}
                  height={theme.spacing(10)}
                >
                  {data.icon && (
                    <Icon
                      type={IconType[data.icon]}
                      varient={IconVarient.solid}
                      size={16}
                      color={theme.colors.gray[500]}
                    />
                  )}
                  <Typography
                    varient="textxs"
                    mdWeightVarient="regular"
                    color={
                      data.tabKeyName === tabName
                        ? theme.colors.primary[700]
                        : theme.colors.gray[500]
                    }
                  >
                    {data.content}
                  </Typography>
                </Box>
              ) : (
                <Box
                  ml={
                    tabType === "chips" && index !== 0
                      ? theme.spacing(2)
                      : theme.spacing(0)
                  }
                >
                  <Badge
                    text={data.content}
                    badgeStyle={
                      data.tabKeyName === tabName
                        ? data.badgeStyle || "dark"
                        : "outline"
                    }
                    colorVarient={
                      data.tabKeyName === tabName
                        ? data.colorVarient || "black"
                        : "gray"
                    }
                    size={data.size}
                    leadingIcon={data.icon}
                  />
                </Box>
              )}
            </UnstyledButton>
          );
        })}
      </Box>
    </Box>
  );
};

export default Tabs;
