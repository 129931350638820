import styled, { css } from "styled-components";
import { layout, position, space } from "styled-system";
import { UnstyledButtonProps } from "./types";

export const StyledUnstyledButton = styled.button.attrs(
  (props: UnstyledButtonProps) => ({
    "data-mixpanel": JSON.stringify(props.dataMixpanel),
  })
)<UnstyledButtonProps>`
  ${space}
  ${layout}
  ${position}
  outline: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: auto;
  height: auto;
  color: currentColor;
  &:focus-visible {
    outline: -webkit-focus-ring-color auto 1px;
  }

  ${({ gap }: any) =>
    gap &&
    css`
      gap: ${gap};
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
`;
