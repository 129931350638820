import { theme } from "@/theme";
import styled from "styled-components";

interface DotStypeProps {
  size?: number;
}

export const DotStyle = styled.div<DotStypeProps>`
  width: ${({ size }) => (size ? `${size}px` : "4px")};
  height: ${({ size }) => (size ? `${size}px` : "4px")};
  border-radius: 50%;
  background-color: ${({ color }) => color || theme.colors.primary[400]};
`;
