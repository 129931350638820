import Typography from "@/design-system-v2/Typography";
import Box from "@/design-system/Box";
import { theme } from "@/theme";

export const PopupCard = ({ popupText }: { popupText: string }) => {
  return (
    <Box padding={theme.spacing(2)}>
      <Typography
        varient="displayxs"
        weightVarient="regular"
        color={theme.colors.base.black}
      >
        {popupText}
      </Typography>
    </Box>
  );
};

export const steps = [
  {
    selector: "",
    content: () => (
      <PopupCard
        popupText={"Welcome to LMS, let’s take you through a tour of it"}
      />
    ),
  },
  {
    selector: "#dropdownButton-maskId",
    content: () => (
      <PopupCard popupText={"Access dashboard, profile and your orders here"} />
    ),
  },
  {
    selector: "#dashboard-maskId",
    content: <PopupCard popupText={"Access Dashboard of the course here"} />,
  },
  {
    selector: "#Attendance-btn-maskId",
    content: (
      <PopupCard popupText={"Know your attendance of the course here"} />
    ),
  },
  {
    selector: "#PendingTask-maskId",
    content: <PopupCard popupText={"Know your To do tasks here"} />,
  },
  {
    selector: "#UpcomingSchedule-maskId",
    content: (
      <PopupCard popupText={"Know your upcoming live sessions details here"} />
    ),
  },
  {
    selector: "#queries-maskId",
    content: (
      <PopupCard
        popupText={"Ask queries to your IGs and view responses here"}
      />
    ),
  },
  {
    selector: "#skill-progress-maskId",
    content: (
      <PopupCard popupText={"Target your skills in various skill areas here"} />
    ),
  },
  {
    selector: "#performance-maskId",
    content: (
      <PopupCard
        popupText={
          "Use this to track your performance throughout the course here"
        }
      />
    ),
  },
  {
    selector: "#tasks-maskId",
    content: (
      <PopupCard
        popupText={"Use this to see your pending, in review and reviewed tasks"}
      />
    ),
  },
];
