import { useRouter } from "next/router";
import React from "react";

import { trackleadform } from "@/analytics/analytics";

import Box from "@/design-system/Box";
import Icon from "@/design-system/Icon";
import { IconType, IconVarient } from "@/design-system/Icon/types";

import PrimaryButton from "@/design-system-v3/Button/PrimaryButton";
import TertiaryButton from "@/design-system-v3/Button/TertiaryButton";
import Typography from "@/design-system-v3/Typography";

import { routerPage } from "@/utils/utils";

import Progressbar from "@/components/CommonComps/ProgressBar/ProgressBar";

import { theme } from "@/theme";

import { FormComponentPropTypes } from "../type";

export default function FormComponent({
  skipForm,
  components,
  direction,
  handleClose,
  payload,
  setSelectedQuestionIndex,
  notificationId,
  mixpanelObj,
}: FormComponentPropTypes) {
  const router = useRouter();
  const routePageName = routerPage(router.pathname);
  const getSwitchComponent = (element: any) => {
    switch (element.type) {
    case "HEADER_TEXT":
      return (
        <Typography color={theme.colors.gray[800]} varient="heading5" weightVarient="semibold">
          {element.value}
        </Typography>
      );
    case "SUBHEADER_TEXT":
      return (
        <Typography color={theme.colors.gray[600]} varient="bodys" weightVarient="medium">
          {element.value}
        </Typography>
      );
    case "LATER_BUTTON":
      return (
        <TertiaryButton
          style={{
            height: "fit-content",
            padding: "0px",
          }}
          color="neutral"
          label={element.buttonText}
          onClick={() => {
            handleClose(element.buttonText);
          }}
        />
      );
    case "SUBMIT_BUTTON": {
      return (
        <PrimaryButton
          suffixIcon={
            <Icon
              color={"white"}
              type={IconType["arrow-narrow-right"]}
              varient={IconVarient.line}
              size={14}
            />
          }
          color="neutral"
          type="submit"
          label={element.buttonText}
          size="xs"
          onClick={() => {
            skipForm({
              page: routePageName,
              cohortId: router?.query?.cohortId,
              isSkipped: false,
              notificationId: notificationId,
              body: payload,
            });
            trackleadform("Button_Click", { ...mixpanelObj, btnText: element.buttonText });
            setSelectedQuestionIndex((prevValue) => prevValue + 1);
          }}
        />
      );
    }
    case "PROGRESS_BAR":
      return (
        <Progressbar
          accentColor={element.accentColor}
          totalCount={element.totalCount}
          activeCount={element.activeCount}
        />
      );
    }
  };
  return (
    <Box
      display={"flex"}
      gap="8px"
      flexDirection={direction === "vertical" ? "column" : "row"}
      justifyContent={
        direction === "vertical" ? "center" : components.length == 1 ? "flex-end" : "space-between"
      }
      alignItems={direction === "vertical" ? "flex-start" : "center"}
    >
      {components.map((element: any, index: number) => {
        return (
          <React.Fragment key={`header-component-${index}`}>
            {getSwitchComponent(element)}
          </React.Fragment>
        );
      })}
    </Box>
  );
}
