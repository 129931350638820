import LinkButton from "@/design-system-v2/Button/LinkButton";
import FlexContainer from "@/design-system-v2/FlexContainer";
import Typography from "@/design-system-v2/Typography";
import Box from "@/design-system/Box";
import Icon from "@/design-system/Icon";
import { theme } from "@/theme";
import ExportedImage from "next-image-export-optimizer";
import { useRouter } from "next/router";
import styled from "styled-components";

const CardWrapper = styled(Box)`
  max-width: 280px;
  min-height: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    min-width: 100%;
  }
  .link_button {
    padding: 0px;
    height: auto;
  }
`;

interface CardProps {
  data: {
    title: string;
    nextCohort: string;
    timing: string;
    img: string;
    link: string;
  };
}

const FreeCard = ({ data }: CardProps) => {
  const { title, nextCohort, timing, img, link } = data;
  const router = useRouter();

  return (
    <CardWrapper
      bg={theme.colors.base.white}
      borderRadius={theme.radius(4)}
      px={theme.spacing(2)}
      py={theme.spacing(2)}
      boxShadow={theme.shadows.primary["xs"]}
    >
      <FlexContainer flexDirection={"column"} minHeight={"100%"}>
        <ExportedImage
          src={img}
          alt="creative"
          width={247}
          height={120}
          style={{
            width: "100%",
            objectFit: "cover",
            borderRadius: theme.spacing(4),
          }}
        />
        <FlexContainer
          flexGrow={"1"}
          flexDirection={"column"}
          alignItems={"flex-start"}
          justifyContent={"space-between"}
          px={theme.spacing(4)}
          py={theme.spacing(4)}
        >
          <Box>
            <Typography
              varient={"displayxs"}
              weightVarient={"semibold"}
              color={theme.colors.grayCool[800]}
            >
              {title}
            </Typography>
            <Typography
              varient={"textsm"}
              weightVarient={"medium"}
              mt={{
                _: theme.spacing(1),
                md: theme.spacing(2),
              }}
            >
              {timing}
            </Typography>
            <Typography
              varient={"textxs"}
              weightVarient={"regular"}
              mt={{
                _: theme.spacing(2),
                md: theme.spacing(3),
              }}
            >
              Next Cohort
            </Typography>
            <Typography varient={"textsm"} weightVarient={"semibold"} mt={1}>
              {nextCohort}
            </Typography>
          </Box>

          <LinkButton
            label={"Learn More"}
            size={"sm"}
            mt={{
              _: theme.spacing(4),
              md: theme.spacing(5),
            }}
            className={"link_button"}
            suffixIcon={
              <Icon
                varient={"line"}
                type={"arrow-right"}
                color={theme.colors.purple[700]}
              />
            }
            onClick={() => router.push(link)}
            fullWidth={false}
          />
        </FlexContainer>
      </FlexContainer>
    </CardWrapper>
  );
};

export default FreeCard;
