const CoursesDynamicData = [
  {
    cohortId: "BIM_A_017",
    courseId: "BIM",
    upcomingCohortTitle: "3rd August, 2024",
    upcomingCohortDuration: "9 Months, online (10-12 hours/week)",
    periodicity: 7,
  },
  // {
  //   cohortId: "BIM_A_014",
  //   courseId: "BIM",
  //   upcomingCohortTitle: "1st June, 2024",
  //   upcomingCohortDuration: "9 Months, online (10-12 hours/week)",
  //   periodicity: 7,
  // },
  // {
  //   cohortId: "BIM_A_013",
  //   courseId: "BIM",
  //   upcomingCohortTitle: "16th December, 2023",
  //   upcomingCohortDuration: "6 Months, online (5-6 hours/week)",
  //   periodicity: 7,
  // },
  // {
  //   cohortId: "BIM_A_012",
  //   courseId: "BIM",
  //   upcomingCohortTitle: "21st October, 2023",
  //   upcomingCohortDuration: "6 Months, online (5-6 hours/week)",
  //   periodicity: 7,
  // },
  // {
  //   cohortId: "BIM_A_011",
  //   courseId: "BIM",
  //   upcomingCohortTitle: "26th August, 2023",
  //   upcomingCohortDuration: "6 Months, online (5-6 hours/week)",
  //   periodicity: 7,
  // },
  // {
  //   cohortId: "BIM_A_010",
  //   courseId: "BIM",
  //   upcomingCohortTitle: "15th July, 2023",
  //   upcomingCohortDuration: "6 Months, online (5-6 hours/week)",
  //   periodicity: 7,
  // },
  // {
  //   cohortId: "BIM_A_009",
  //   courseId: "BIM",
  //   upcomingCohortTitle: "20th May, 2023",
  //   upcomingCohortDuration: "6 Months, online (5-6 hours/week)",
  //   periodicity: 7,
  // },
  {
    cohortId: "acm007",
    courseId: "acm",
    upcomingCohortTitle: "20th July, 2024",
    upcomingCohortDuration: "10 Months, online (10-12 hours/week)",
  },
  // {
  //   cohortId: "acm006",
  //   courseId: "acm",
  //   upcomingCohortTitle: "23rd December, 2023",
  //   upcomingCohortDuration: "10 Months, online (10-12 hours/week)",
  // },
  // {
  //   cohortId: "acm005",
  //   courseId: "acm",
  //   upcomingCohortTitle: "28th October, 2023",
  //   upcomingCohortDuration: "8 Months, online (8-10 hours/week)",
  // },
  // {
  //   cohortId: "acm004",
  //   courseId: "acm",
  //   upcomingCohortTitle: "19th August, 2023",
  //   upcomingCohortDuration: "8 Months, online (8-10 hours/week)",
  // },
  // {
  //   cohortId: "acm003",
  //   courseId: "acm",
  //   upcomingCohortTitle: "17th June, 2023",
  //   upcomingCohortDuration: "8 Months, online (8-10 hours/week)",
  // },
  // {
  //   cohortId: "acm002",
  //   courseId: "acm",
  //   upcomingCohortTitle: "29th April , 2023",
  //   upcomingCohortDuration: "8 Months, online (8-10 hours/week)",
  // },
  {
    cohortId: "BIM_C_007",
    courseId: "BIM_C",
    upcomingCohortTitle: "27th July, 2024",
    upcomingCohortDuration: "8 Months, online (10-12 hours/week)",
  },
  // {
  //   cohortId: "BIM_C_006",
  //   courseId: "BIM_C",
  //   upcomingCohortTitle: "25th May, 2024",
  //   upcomingCohortDuration: "8 Months, online (10-12 hours/week)",
  // },
  // {
  //   cohortId: "BIM_C_005",
  //   courseId: "BIM_C",
  //   upcomingCohortTitle: "23rd March, 2024",
  //   upcomingCohortDuration: "8 Months, online (10-12 hours/week)",
  // },
  // {
  //   cohortId: "BIM_C_004",
  //   courseId: "BIM_C",
  //   upcomingCohortTitle: "13th January, 2024",
  //   upcomingCohortDuration: "8 Months, online (10-12 hours/week)",
  // },
  // {
  //   cohortId: "BIM_C_003",
  //   courseId: "BIM_C",
  //   upcomingCohortTitle: "18th November, 2023",
  //   upcomingCohortDuration: "7 Months, online (7-8 hours/week)",
  // },
  // {
  //   cohortId: "BIM_C_001",
  //   courseId: "BIM_C",
  //   upcomingCohortTitle: "12th July, 2023",
  //   upcomingCohortDuration: "7 Months, online (7-8 hours/week)",
  // },
];

export const freeCourseData = [
  {
    title: "BIM Professional Course for Architects 2.0",
    nextCohort: "3rd August, 2024",
    timing: "9 Months, online (10-12 hours/week)",
    img: `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/learner-hub/course-images/v2/vertical-BIM_A.svg`,
    link: "/courses/building-information-modelling",
  },
  {
    title: "Master Computational Design Course",
    nextCohort: "20th July, 2024",
    timing: "10 Months, online (10-12 hours/week)",
    img: `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/learner-hub/course-images/v2/vertical-MCDC.svg`,
    link: "/courses/computational-design",
  },
  {
    title: "BIM Professional Course for Civil Engineers",
    nextCohort: "27th July, 2024",
    timing: "8 Months, online (10-12 hours/week)",
    img: `${process.env.NEXT_PUBLIC_ASSETS}/images-v2/learner-hub/course-images/v2/vertical-BIM_C.svg`,
    link: "/courses/bim-for-civil-engineers",
  },
];

export default CoursesDynamicData;
