import { FC } from "react";
import { StyledFlexContainer } from "./flexcontainer.styles";
import { FlexContainerProps } from "./types";

const FlexContainer: FC<FlexContainerProps> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <StyledFlexContainer {...rest} className={className}>
      {children}
    </StyledFlexContainer>
  );
};
export default FlexContainer;
