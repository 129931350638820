import { DefaultTheme } from "styled-components";
import { colors } from "./colors";

export const theme: DefaultTheme = {
  breakpoints: {
    xs: "0px",
    sm: "600px",
    md: "1080px",
    lg: "1368px",
    xl: "1520px",
    xxl: "1840px",
  },
  colors: colors,
  spacing: (factor: number) => `${0.25 * factor}rem`,
  radius: (factor: number) => `${0.25 * factor}rem`,
  shadows: {
    primary: {
      xs: "0px 6px 4px rgba(0, 0, 0, 0.02), 0px 3px 3px rgba(0, 0, 0, 0.03), 0px 1px 2px rgba(0, 0, 0, 0.03), 0px 0px 0px rgba(0, 0, 0, 0.03)",
      sm: "0px 16px 10px rgba(0, 0, 0, 0.02), 0px 7px 7px rgba(0, 0, 0, 0.03), 0px 2px 4px rgba(0, 0, 0, 0.03), 0px 0px 0px rgba(0, 0, 0, 0.03)",
      md: "0px 32px 19px rgba(0, 0, 0, 0.02), 0px 14px 14px rgba(0, 0, 0, 0.03), 0px 4px 8px rgba(0, 0, 0, 0.03), 0px 0px 0px rgba(0, 0, 0, 0.03)",
      lg: "0px 48px 29px rgba(0, 0, 0, 0.02), 0px 21px 21px rgba(0, 0, 0, 0.03), 0px 5px 12px rgba(0, 0, 0, 0.03), 0px 0px 0px rgba(0, 0, 0, 0.03)",
      xl: "0px 79px 48px rgba(0, 0, 0, 0.02), 0px 35px 35px rgba(0, 0, 0, 0.03), 0px 9px 19px rgba(0, 0, 0, 0.03), 0px 0px 0px rgba(0, 0, 0, 0.03)",
      xxl: "0px 111px 67px rgba(0, 0, 0, 0.02), 0px 49px 49px rgba(0, 0, 0, 0.03), 0px 12px 27px rgba(0, 0, 0, 0.03), 0px 0px 0px rgba(0, 0, 0, 0.03)",
    },
    light: {
      xs: "0px 6px 4px rgba(165, 117, 244, 0.03), 0px 3px 3px rgba(165, 117, 244, 0.04), 0px 1px 2px rgba(165, 117, 244, 0.05), 0px 0px 0px rgba(165, 117, 244, 0.05)",
      sm: "0px 16px 10px rgba(165, 117, 244, 0.03), 0px 7px 7px rgba(165, 117, 244, 0.04), 0px 2px 4px rgba(165, 117, 244, 0.05), 0px 0px 0px rgba(165, 117, 244, 0.05)",
      md: "0px 32px 19px rgba(165, 117, 244, 0.03), 0px 14px 14px rgba(165, 117, 244, 0.04), 0px 4px 8px rgba(165, 117, 244, 0.05), 0px 0px 0px rgba(165, 117, 244, 0.05)",
      lg: "0px 48px 29px rgba(165, 117, 244, 0.03), 0px 21px 21px rgba(165, 117, 244, 0.04), 0px 5px 12px rgba(165, 117, 244, 0.05), 0px 0px 0px rgba(165, 117, 244, 0.05)",
      xl: "0px 79px 48px rgba(165, 117, 244, 0.03), 0px 35px 35px rgba(165, 117, 244, 0.04), 0px 9px 19px rgba(165, 117, 244, 0.05), 0px 0px 0px rgba(165, 117, 244, 0.05)",
      xxl: "0px 111px 67px rgba(165, 117, 244, 0.03), 0px 49px 49px rgba(165, 117, 244, 0.04), 0px 12px 27px rgba(165, 117, 244, 0.05), 0px 0px 0px rgba(165, 117, 244, 0.05)",
    },
    dark: {
      xs: "0px 6px 4px rgba(0, 0, 0, 0.05), 0px 3px 3px rgba(0, 0, 0, 0.09), 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1)",
      sm: "0px 16px 10px rgba(0, 0, 0, 0.05), 0px 7px 7px rgba(0, 0, 0, 0.09), 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1)",
      md: "0px 32px 19px rgba(0, 0, 0, 0.05), 0px 14px 14px rgba(0, 0, 0, 0.09), 0px 4px 8px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1)",
      lg: "0px 48px 29px rgba(0, 0, 0, 0.05), 0px 21px 21px rgba(0, 0, 0, 0.09), 0px 5px 12px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1)",
      xl: "0px 79px 48px rgba(0, 0, 0, 0.05), 0px 35px 35px rgba(0, 0, 0, 0.09), 0px 9px 19px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1)",
      xxl: "0px 111px 67px rgba(0, 0, 0, 0.05), 0px 49px 49px rgba(0, 0, 0, 0.09), 0px 12px 27px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1)",
    },
  },
  gradients: {
    primaryGradients45deg: {
      "1": `linear-gradient(45deg, ${colors.primary[600]} 0%, ${colors.primary[500]} 100%)`,
      "2": `linear-gradient(45deg, ${colors.primary[700]} 0%,  ${colors.primary[500]} 100%)`,
      "3": `linear-gradient(45deg, ${colors.primary[700]} 0%, ${colors.primary[600]} 100%)`,
      "4": `linear-gradient(45deg, ${colors.primary[800]} 0%, ${colors.primary[500]} 100%)`,
      "5": `linear-gradient(45deg, ${colors.primary[800]} 0%, ${colors.primary[600]} 100%)`,
      "6": `linear-gradient(45deg, ${colors.primary[900]} 0%, ${colors.primary[600]} 100%)`,
      "7": `linear-gradient(45deg, ${colors.primary[900]} 0%, ${colors.primary[700]} 100%)`,
    },
    primaryGradients90deg: {
      "1": `linear-gradient(90deg, ${colors.primary[600]} 0%, ${colors.primary[500]} 100%)`,
      "2": `linear-gradient(90deg, ${colors.primary[700]} 0%,  ${colors.primary[500]} 100%)`,
      "3": `linear-gradient(90deg, ${colors.primary[700]} 0%, ${colors.primary[600]} 100%)`,
      "4": `linear-gradient(90deg, ${colors.primary[800]} 0%, ${colors.primary[500]} 100%)`,
      "5": `linear-gradient(90deg, ${colors.primary[800]} 0%, ${colors.primary[600]} 100%)`,
      "6": `linear-gradient(90deg, ${colors.primary[900]} 0%, ${colors.primary[600]} 100%)`,
      "7": `linear-gradient(90deg, ${colors.primary[900]} 0%, ${colors.primary[700]} 100%)`,
    },
    misc: {
      "1": `linear-gradient(90deg, #7860FC 0%, #21B7DE 50%, #7FEB8C 100%)`,
      "2": `linear-gradient(45deg, #198FED 0%, #85F575 100%)`,
      "3": `linear-gradient(45deg, #54E4AD 0%, #27B6DA 50%, #198BEE 100%)`,
      "4": `linear-gradient(66.04deg, #ECE346 0%, #D757A4 50%, #8957FE 100%)`,
      "5": `linear-gradient(90deg, #6938EF 0%, #DA45C2 50%, #E5C360 100%)`,
      "6": `linear-gradient(45deg, #F29E62 0%, #C263B5 50%, #8A57FD 100%)`,
      "7": `linear-gradient(45deg, #9C57E9 0%, #EF5489 50.87%, #E7807C 100%)`,
      "8": `linear-gradient(45deg, #002063 0%, #8154F6 100%)`,
    },
    misc90: {
      "1": `linear-gradient(90deg, #7860FC 0%, #21B7DE 50%, #7FEB8C 100%)`,
      "2": `linear-gradient(45deg, #198FED 0%, #85F575 100%)`,
      "3": `linear-gradient(45deg, #54E4AD 0%, #27B6DA 50%, #198BEE 100%)`,
      "4": `linear-gradient(66.04deg, #ECE346 0%, #D757A4 50%, #8957FE 100%)`,
      "5": `linear-gradient(0deg, #E5C360 0%, #DA45C2 50%, #6938EF 100%)`,
      "6": `linear-gradient(45deg, #F29E62 0%, #C263B5 50%, #8A57FD 100%)`,
      "7": `linear-gradient(45deg, #9C57E9 0%, #EF5489 50.87%, #E7807C 100%)`,
      "8": `linear-gradient(45deg, #002063 0%, #8154F6 100%)`,
    },
    misc180: {
      "5": `linear-gradient(180deg, #E5C360 0%, #DA45C2 50%, #6938EF 100%)`,
    },
    grayNeutral: {
      "1": `linear-gradient(180deg, #FFFFFF 100%, #F3F5F7 100%)`,
      "2": `linear-gradient(180deg, #F9FAFB 100%, #EDF0F3 100%)`,
      "3": `linear-gradient(180deg, #F9FAFB 100%, #E7EBEF 100%)`,
      "4": `linear-gradient(180deg, #F3F5F7 100%, #E0E5EB 100%)`,
      "5": `linear-gradient(180deg, #EDF0F3 100%, #D4DBE2 100%)`,
      "6": `linear-gradient(180deg, #E7EBEF 100%, #C8D1DA 100%)`,
      "7": `linear-gradient(180deg, #E0E5EB 100%, #B6C2CE 100%)`,
    },
    grayTrue: {
      "1": `linear-gradient(180deg, #FFFFFF 100%, #F5F5F5 100%)`,
      "2": `linear-gradient(180deg, #FAFAFA 100%, #F0F0F0 100%)`,
      "3": `linear-gradient(180deg, #FAFAFA 100%, #EBEBEB 100%)`,
      "4": `linear-gradient(180deg, #F5F5F5 100%, #E5E5E5 100%)`,
      "5": `linear-gradient(180deg, #F0F0F0 100%, #DBDBDB 100%)`,
      "6": `linear-gradient(180deg, #EBEBEB 100%, #D1D1D1 100%)`,
      "7": `linear-gradient(180deg, #E5E5E5 100%, #C2C2C2 100%)`,
    },
  },
};
