import { FC } from "react";

import { CustomButtonProps } from "../button-varient";

import { PrimaryStyledButton } from "./primaryButton.styles";

const PrimaryButton: FC<CustomButtonProps> = ({ color, loading, ...rest }) => {
  return <PrimaryStyledButton color={color} loading={loading} {...rest} />;
};

export default PrimaryButton;

PrimaryButton.defaultProps = {
  color: "purple",
  loading: false,
};
