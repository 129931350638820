import { FC } from "react";
import { StyledGridColumn } from "./gridcolumn.styles";
import { GridColumnProps } from "./types";

const GridColumn: FC<GridColumnProps> = ({
  children,
  xs,
  sm,
  md,
  lg,
  xxl,
  ...rest
}) => {
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    <StyledGridColumn xs={xs} sm={sm} md={md} lg={lg} xxl={xxl} {...rest}>
      {children}
    </StyledGridColumn>
  );
};

export default GridColumn;
