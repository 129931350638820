import { HTMLAttributes } from "react";

export interface IconProps extends HTMLAttributes<HTMLElement> {
  color?: any;
  stokeColor?: string;
  varient?: string;
  type: string;
  size?: number;
  onClick?: any;
}

export enum IconType {
  "refresh-ccw-05" = "refresh-ccw-05",
  "face-frown" = "face-frown",
  "check-square-broken" = "check-square-broken",
  "filter-lines" = "filter-lines",
  "info-circle" = "info-circle",
  "stars-01" = "stars-01",
  "eye" = "eye",
  "users-01" = "users-01",
  "check" = "check",
  "activity" = "activity",
  "arrow-down" = "arrow-down",
  "arrow-left" = "arrow-left",
  "chevron-left" = "chevron-left",
  "chevron-right" = "chevron-right",
  "arrow-right" = "arrow-right",
  "check-circle" = "check-circle",
  "x" = "x",
  "arrows-right" = "arrows-right",
  "chevron-down" = "chevron-down",
  "arrows-triangle" = "arrows-triangle",
  "expand-01" = "expand-01",
  "menu-04" = "menu-04",
  "check-square" = "check-square",
  "arrow-narrow-right" = "arrow-narrow-right",
  "arrow-narrow-down" = "arrow-narrow-down",
  "arrow-narrow-up-right" = "arrow-narrow-up-right",
  "x-square" = "x-square",
  "x-close" = "x-close",
  "grid-01" = "grid-01",
  "search-sm" = "search-sm",
  "infinity" = "infinity",
  "line-chart-up-03" = "line-chart-up-03",
  "book-open-01" = "book-open-01",
  "message-square-02" = "message-square-02",
  "message-square-text-02" = "message-square-text-02",
  "award-05" = "award-05",
  "play" = "play",
  "pause-circle" = "pause-circle",
  "download-02" = "download-02",
  "download-01" = "download-01",
  "users-check" = "users-check",
  "users-right" = "users-right",
  "user-03" = "user-03",
  "check-verified-02" = "check-verified-02",
  "check-verified-01" = "check-verified-01",
  "globe-05" = "globe-05",
  "briefcase-02" = "briefcase-02",
  "briefcase-01" = "briefcase-01",
  "bookmark" = "bookmark",
  "bookmark-check" = "bookmark-check",
  "_dot" = "_dot",
  "union" = "union",
  "_check" = "_check",
  "asterix_01" = "asterix_01",
  "asterisk-01" = "asterisk-01",
  "code-snippet-01" = "code-snippet-01",
  "asterisk-02" = "asterisk-02",
  "plus" = "plus",
  "how_you_learn" = "how_you_learn",
  "phone-call-01" = "phone-call-01",
  "rocket-02" = "rocket-02",
  "lightning-01" = "lightning-01",
  "zap_fast" = "zap-fast",
  "clockCheck" = "clock-check",
  "nGraduation" = "graduation-hat-02",
  "globe-06" = "globe-06",
  "star01" = "star-07",
  "briefcase-03" = "briefcase-03",
  "Scaled - N - Graduation" = "Scaled - N - Graduation",
  "file-06" = "file-06",
  "upload-01" = "upload-01",
  "calendar" = "calendar",
  "calendar-date" = "calendar-date",
  "cube-01" = "cube-01",
  "tv-03" = "tv-03",
  "globe-02" = "globe-02",
  "share-06" = "share-06",
  "share-07" = "share-07",
  "chevron-up" = "chevron-up",
  "trend-up-01" = "trend-up-01",
  "star-01" = "star-01",
  "star-05" = "star-05",
  "minus" = "minus",
  "copy-04" = "copy-04",
  "arrow-up-right" = "arrow-up-right",
  "check-heart" = "check-heart",
  "slash-circle-01" = "slash-circle-01",
  "check-circle-broken" = "check-circle-broken",
  "cursor-click-02" = "cursor-click-02",
  "settings-01" = "settings-01",
  "trophy-01" = "trophy-01",
  "clock-stopwatch" = "clock-stopwatch",
  "percent-03" = "percent-03",
  "wallet-02" = "wallet-02",
  "gift-01" = "gift-01",
  "repeat-04" = "repeat-04",
  "file-02" = "file-02",
  "flip-backward" = "flip-backward",
  "user-x-02" = "user-x-02",
  "alert-triangle" = "alert-triangle",
}

export enum IconVarient {
  line = "line",
  thin = "thin",
  duotone = "duotone",
  duocolor = "duocolor",
  solid = "solid",
  misc = "misc",
  fill = "fill",
  scaled = "scaled",
}
