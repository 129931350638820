import {
  BannerTypeResponse,
  CohortId,
  ContactUsApiPayload,
  EligibilityData,
  InterestDetailsFormApiPayload,
  SelfPlacedFormApiPayload,
  UserCohortPlacementPreferenceResponse,
} from "@/components/PlacementHub/types";

import { appBaseCreateApi } from "./BaseQueryApiClient";

export const placementHubClient = appBaseCreateApi({
  reducerPath: "placementHubClient",

  tagTypes: [
    "SPECIFIC-JOB-DETAILS",
    "GET-BANNER-QUERY",
    "GET-ELIGIBILITY-QUERY",
    "GET-ALL-JOBS",
    "GET-ALL-APPLICATIONS",
    "GET-APPLICATION-UPDATES",
    "GET-PLACEMENT-DETAILS",
  ],

  endpoints: (builder) => ({
    getAllJobs: builder.query({
      query: (cohortId: string) => {
        return {
          url: `/v1/client/hiring/user/user-jobs-data?cohortId=${cohortId}`,
        };
      },
      providesTags: ["GET-ALL-JOBS"],
    }),
    getJobDetails: builder.query({
      query: (jobId) => {
        return {
          url: `/v1/client/hiring/user/job-opening?job-id=${jobId}`,
        };
      },
      providesTags: ["SPECIFIC-JOB-DETAILS"],
    }),
    markUserJobActivity: builder.mutation({
      query: (userJobId) => {
        return {
          url: `/v1/client/hiring/user/user-job/mark-activity?user-job-id=${userJobId}`,
          method: "PUT",
        };
      },
      invalidatesTags: ["GET-APPLICATION-UPDATES"],
    }),
    applyJob: builder.mutation({
      query: (userJobData) => {
        return {
          url: `/v1/client/hiring/user/user-job`,
          method: "POST",
          body: userJobData,
        };
      },
      invalidatesTags: ["SPECIFIC-JOB-DETAILS", "GET-ALL-JOBS", "GET-ALL-APPLICATIONS"],
    }),
    jobOfferReview: builder.mutation({
      query: (reviewData) => {
        return {
          url: `v1/client/hiring/user/submit-job-feedback`,
          method: "POST",
          body: reviewData,
        };
      },
      invalidatesTags: ["GET-ELIGIBILITY-QUERY"],
    }),
    jobOfferReply: builder.mutation({
      query: (userResponse) => {
        return {
          url: `/v1/client/hiring/user/update-offer-status`,
          method: "PUT",
          body: userResponse,
        };
      },
      invalidatesTags: [
        "SPECIFIC-JOB-DETAILS",
        "GET-ELIGIBILITY-QUERY",
        "GET-ALL-JOBS",
        "GET-ALL-APPLICATIONS",
      ],
    }),
    getFaqs: builder.query({
      query: ({ pageId }) => {
        return {
          url: `v1/faq/fetch-faqs?pageId=${pageId}`,
        };
      },
    }),
    getEligibilityInfo: builder.query<EligibilityData, CohortId>({
      query: ({ cohortId }) => {
        return {
          url: `v1/client/hiring/user/check-eligibility-criteria?cohortId=${cohortId}`,
        };
      },
      providesTags: ["GET-ELIGIBILITY-QUERY"],
      // keepUnusedDataFor: 0,
    }),
    getAllApplications: builder.query({
      query: (cohortId: string) => {
        return {
          url: `/v1/client/hiring/user/applied-jobs-state?cohortId=${cohortId}`,
        };
      },
      providesTags: ["GET-ALL-APPLICATIONS"],
    }),
    getBannerType: builder.query<BannerTypeResponse, CohortId>({
      query: ({ cohortId }) => {
        return {
          url: `/v1/client/hiring/user/banner-type?cohortId=${cohortId}`,
        };
      },
      providesTags: ["GET-BANNER-QUERY"],
    }),
    getApplicationUpdates: builder.query({
      query: () => {
        return {
          url: `/v1/client/hiring/user/application-updates`,
        };
      },
      providesTags: ["GET-APPLICATION-UPDATES"],
    }),
    contactUs: builder.mutation({
      query: (payload: ContactUsApiPayload) => {
        return {
          url: `/v2/client/hiring/user/contact-us?jobId=${payload?.jobId}`,
          method: "POST",
          body: payload,
        };
      },
    }),
    submitSelfPlaced: builder.mutation({
      query: (payload: SelfPlacedFormApiPayload) => {
        return {
          url: `/v1/client/hiring/user/submit-self-placed`,
          method: "POST",
          body: payload,
        };
      },
    }),
    saveInterestDetails: builder.mutation({
      query: (payload: InterestDetailsFormApiPayload) => {
        return {
          url: `/v1/client/hiring/user/placement-details`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: ["GET-BANNER-QUERY", "GET-ELIGIBILITY-QUERY", "GET-PLACEMENT-DETAILS"],
    }),
    getAllCohortPlacementDetails: builder.query<UserCohortPlacementPreferenceResponse, void>({
      query: () => {
        return {
          url: `/v1/client/hiring/user/placement-preferences`,
        };
      },
      providesTags: ["GET-PLACEMENT-DETAILS"],
    }),
  }),
});

export const {
  useGetAllJobsQuery,
  useGetJobDetailsQuery,
  useMarkUserJobActivityMutation,
  useApplyJobMutation,
  useJobOfferReplyMutation,
  useJobOfferReviewMutation,
  useGetAllApplicationsQuery,
  useGetBannerTypeQuery,
  useGetApplicationUpdatesQuery,
  useContactUsMutation,
  useSubmitSelfPlacedMutation,
  useGetFaqsQuery,
  useGetEligibilityInfoQuery,
  useSaveInterestDetailsMutation,
  useGetAllCohortPlacementDetailsQuery,
} = placementHubClient;
