import { FC } from "react";

import { StyledButton } from "./button.styles";
import { ButtonProps } from "./types";

const Button: FC<ButtonProps> = ({
  color,
  loading,
  size,
  icon,
  suffixIcon,
  label,
  className,
  ...rest
}) => {
  return (
    <StyledButton color={color} size={size} {...rest} className={className}>
      {loading ? (
        // Determine the loader class based on the color prop
        <span className={`loader loader-${color}`} />
      ) : (
        <>
          {icon && <div className="icon">{icon}</div>}
          {label}
          {suffixIcon && <div className="suffix">{suffixIcon}</div>}
        </>
      )}
    </StyledButton>
  );
};

export default Button;

Button.defaultProps = {
  color: "primary",
  size: "md",
  icon: "",
  suffixIcon: "",
  label: "",
};
