import { FC } from "react";
import { SecondaryStyledButton } from "./secondaryButton.styles";
import { SecondaryButtonProps } from "./types";

const SecondaryButton: FC<SecondaryButtonProps> = ({ color, ...rest }) => {
  return <SecondaryStyledButton color={color} {...rest} />;
};

export default SecondaryButton;

SecondaryButton.defaultProps = {
  color: "default",
};
