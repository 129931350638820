import Icon from "@/design-system/Icon";
import { useRouter } from "next/router";
import styled from "styled-components";
import {
  LockedItemImageContainer,
  SidebarItemText,
  SidebarMenuItem,
} from "../sidebarStyle";
import { LinkWrapper } from "./LinkWrapper";

const Tooltip = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
`;

interface TooltipPropTypes {
  locked: boolean;
}

const TooltipText = styled.div<TooltipPropTypes>`
  position: relative;
  visibility: hidden;
  width: auto;
  background-color: ${({ locked }) => (locked ? "#EAECF0" : "#333")};
  color: ${({ locked }) => (locked ? "#fff" : "#fff")};
  text-align: start;
  border-radius: 6px;
  padding: 8px;
  position: absolute;
  z-index: 1;
  left: 60px;
  opacity: 0;
  transition: opacity 0.3s;
  white-space: nowrap;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: -10px;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: ${({ locked }) =>
      locked
        ? "transparent #EAECF0 transparent transparent"
        : "transparent #111322 transparent transparent"};
  }
`;

const TooltipContainer = styled.div`
  &:hover ${TooltipText} {
    visibility: visible;
    opacity: 1;
  }
`;

const GlobalNavBar = ({
  items,
  globalSideBarToggle,
  isMentorDashboard,
  isLeadMentorDashboard,
  lockedPages,
}) => {
  const router = useRouter();
  return (
    <>
      {items.map((item) => {
        const isLocked = lockedPages.includes(item.link) || item.locked;
        const isCurrentRouteActive = router.pathname.includes(item?.link);
        if (
          router.query.cohortId.includes("acm") &&
          item?.text === "Capstone"
        ) {
          return null;
        }
        return (
          <LinkWrapper
            key={item?.id}
            href={`/${
              isLeadMentorDashboard
                ? "lm"
                : isMentorDashboard
                ? "mentor"
                : "learn"
            }/${router.query.cohortId}/${item.link}`}
            disabled={isLocked}
          >
            <TooltipContainer>
              <Tooltip>
                <SidebarMenuItem
                  id={`${item.link}-maskId`}
                  locked={isLocked}
                  // toolTipOnHover={item.toolTipOnHover}
                  active={isCurrentRouteActive}
                >
                  {globalSideBarToggle ? (
                    <Icon
                      type={item.image}
                      varient="line"
                      color={
                        isLocked
                          ? "#B9C0D4"
                          : isCurrentRouteActive
                          ? "#5925DC"
                          : "#30374F"
                      }
                      size={20}
                    />
                  ) : (
                    <LockedItemImageContainer>
                      <Icon
                        type={item.image}
                        varient="line"
                        color={
                          isLocked
                            ? "#B9C0D4"
                            : isCurrentRouteActive
                            ? "#5925DC"
                            : "#30374F"
                        }
                        size={20}
                      />
                      <SidebarItemText
                        // hideText={globalSideBarToggle}
                        active={isCurrentRouteActive}
                        style={{
                          color: isLocked
                            ? "#7D89B0"
                            : isCurrentRouteActive
                            ? "#5925DC"
                            : "#30374F",
                        }}
                      >
                        {item.text}
                      </SidebarItemText>
                      {isLocked && (
                        <div>
                          <Icon
                            type={item.lockedIcon}
                            varient="line"
                            color="#B9C0D4"
                            size={20}
                          />
                        </div>
                      )}
                    </LockedItemImageContainer>
                  )}
                  {globalSideBarToggle && (
                    <TooltipText locked={isLocked}>
                      {isLocked && item.toolTipLockedOnHover
                        ? item.toolTipLockedOnHover
                        : item.toolTipOnHover}
                    </TooltipText>
                  )}
                </SidebarMenuItem>
              </Tooltip>
            </TooltipContainer>
          </LinkWrapper>
        );
      })}
    </>
  );
};

export default GlobalNavBar;
