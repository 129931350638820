import styled, { css } from "styled-components";
import { layout, space } from "styled-system";
import Button from "..";
import { LinkButtonProps } from "./types";

export const LinkStyledButton = styled(Button)<LinkButtonProps>`
  ${space}
  ${layout}
  ${({ color }) =>
    color === "default" &&
    css`
      background-color: transparent;
      color: ${({ theme }) => theme.colors.purple[700]};
      &:hover {
        //background-color: ${({ theme }) => theme.colors.purple[800]};
        text-decoration: underline;
        /* box-shadow: 0px 16px 10px rgba(0, 0, 0, 0.02),
          0px 7px 7px rgba(0, 0, 0, 0.03), 0px 2px 4px rgba(0, 0, 0, 0.03),
          0px 0px 0px rgba(0, 0, 0, 0.03); */
      }
      &:active {
        color: ${({ theme }) => theme.colors.purple[800]};
        text-decoration: none;
      }
      &:disabled {
        color: ${({ theme }) => theme.colors.purple[300]};
        text-decoration: none;
      }
    `}

  ${({ color }) =>
    color === "neutral" &&
    css`
      background-color: transparent;
      color: ${({ theme }) => theme.colors.gray[800]};
      &:hover {
        text-decoration: underline;
      }
      &:active {
        color: ${({ theme }) => theme.colors.gray[900]};
        text-decoration: none;
      }
      &:disabled {
        color: ${({ theme }) => theme.colors.gray[300]};
        text-decoration: none;
      }
    `}

    ${({ color }) =>
    color === "destructive" &&
    css`
      color: ${({ theme }) => theme.colors.error[600]};

      &:hover {
        color: ${({ theme }) => theme.colors.error[800]};
        text-decoration: underline;
      }
      &:active {
        color: ${({ theme }) => theme.colors.error[700]};
        text-decoration: none;
      }
      &:disabled {
        color: ${({ theme }) => theme.colors.error[300]};
        text-decoration: none;
      }
    `}
      ${({ disableGutter }) =>
    disableGutter &&
    css`
      padding: 0;
      height: auto;
    `}
`;
