import Button from "@/design-system-v3/Button";
import styled, { css } from "styled-components";
import { layout, space } from "styled-system";
import { TertiaryButtonProps } from "./types";

export const TertiaryStyledButton = styled(Button)<TertiaryButtonProps>`
  ${space}
  ${layout}
  ${({ color }) =>
    color === "purple" &&
    css`
      background-color: transparent;
      color: ${({ theme }) => theme.colors.purple[600]};
      &:hover {
        color: ${({ theme }) => theme.colors.purple[800]};
      }
      &:active {
        color: ${({ theme }) => theme.colors.purple[500]};
      }
      &:disabled {
        color: ${({ theme }) => theme.colors.purple[300]};
      }
    `}

  ${({ color }) =>
    color === "neutral" &&
    css`
      background-color: transparent;
      color: ${({ theme }) => theme.colors.grayCool[900]};
      &:hover {
        color: ${({ theme }) => theme.colors.base.black};
      }
      &:active {
        color: ${({ theme }) => theme.colors.grayCool[500]};
      }
      &:disabled {
        color: ${({ theme }) => theme.colors.grayCool[200]};
      }
    `}

    ${({ color }) =>
    color === "neutralDark" &&
    css`
      background-color: transparent;
      color: ${({ theme }) => theme.colors.base.white};
      &:hover {
        color: ${({ theme }) => theme.colors.grayCool[200]};
      }
      &:active {
        color: ${({ theme }) => theme.colors.grayCool[400]};
      }
      &:disabled {
        color: ${({ theme }) => theme.colors.grayCool[600]};
      }
    `}
      ${({ disableGutter }) =>
    disableGutter &&
    css`
      padding: 0;
      height: auto;
    `}
`;
