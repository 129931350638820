import { FC } from "react";
import { UnstyledButtonProps } from "./types";
import { StyledUnstyledButton } from "./unstyledbutton.styles";

const UnstyledButton: FC<UnstyledButtonProps & { buttonHeadline?: string }> = ({
  children,
  buttonHeadline,
  ...rest
}) => {
  return (
    <StyledUnstyledButton {...rest} data-headline={buttonHeadline}>
      {children}
    </StyledUnstyledButton>
  );
};

export default UnstyledButton;
