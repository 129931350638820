export interface EligibilityData {
  placementFormAvailable?: boolean;
  placementFormFilled?: boolean;
  overallPercent: number;
  eligiblePercent: number;
  isGraduated: "IN_PROGRESS" | "FULFILLED" | "NOT_FULFILLED";
  cvSubmitted: boolean;
  portfolioSubmitted: boolean;
  profileCompleted: "IN_PROGRESS" | "FULFILLED" | "NOT_FULFILLED";
  reviewDayDate?: string;
  attendedReviewDay: "IN_PROGRESS" | "FULFILLED" | "NOT_FULFILLED";
  isEligibleForPlacements:
    | "ELIGIBILITY_IN_PROGRESS"
    | "ELIGIBLE"
    | "NOT_ELIGIBLE"
    | "PROFILE_NOT_COMPLETED";
  cohortPlacementUnlocked: boolean;
  interestedInPlacements: boolean;
  jobsAvailable: boolean;
  jobsUnlocked: boolean;
  hideEvaluation: boolean;
  hidePlacement: boolean;
  userPlacementData: {
    jobsApplied: number;
    jobsInProgress: number;
    isJobOffered: boolean;
    isUserPlaced: boolean;
    didUserDecline: boolean;
    isUserDeclineInvalid: boolean;
    disqualifiedUserJobsCount: number;
    placedUserJob: any;
    placedJob: {
      meta: any;
      jobId: number;
      companyId: number;
      companyName: string;
      companyLogoUrl: string;
      positionId: string;
    }[];
  };
}

export interface SelfPlacedFormApiPayload {
  userId: string;
  formResponse: {
    questionId: string;
    response?: string;
  }[];
}

export interface ContactUsApiPayload {
  userId: string;
  title: string;
  message: string;
  jobId?: string;
}

export interface InterestDetailsFormApiPayload {
  cohortId: string;
  placementDetails: {
    projectSpecialization?: string[];
    priorRevitExp?: string;
    willingToRelocate?: boolean;
    locationPreferences?: string;
    currentCtc?: string;
    expectedCtc?: string;
    noticePeriod?: number;
    currency?: string;
    interestedInPlacements?: boolean;
    closeInterestPopup?: boolean;
  };
  location?: {
    country?: {
      code: string;
      label: string;
    };
    state?: string;
    city?: string;
  };
}
export enum BannerType {
  "PLACEMENT_FORM_NOT_AVAILABLE" = "PLACEMENT_FORM_NOT_AVAILABLE",
  "PLACEMENT_FORM_NOT_FILLED" = "PLACEMENT_FORM_NOT_FILLED",
  "PLACEMENT_FORM_FILLED" = "PLACEMENT_FORM_FILLED",
  "ELIGIBLE_FOR_PLACEMENT" = "ELIGIBLE_FOR_PLACEMENT",
  "NOT_INTERESTED_IN_PLACEMENT" = "NOT_INTERESTED_IN_PLACEMENT",
  "NOT_ELIGIBLE_FOR_PLACEMENT" = "NOT_ELIGIBLE_FOR_PLACEMENT",
  "APPLIED_AT_LEAST_ONE_JOB" = "APPLIED_AT_LEAST_ONE_JOB",
  "ELIGIBLE_BUT_FORM_NOT_FILLED" = "ELIGIBLE_BUT_FORM_NOT_FILLED",
}

export interface BannerTypeResponse {
  bannerType: BannerType;
  hidePlacement: boolean;
}

export interface CohortId {
  cohortId: string;
}

export interface UserCohortPlacementDetails {
  interestedInPlacements: boolean;
  countryPreferences: string[];
  locationPreferences: string[];
  currentCtc: string;
  expectedCtc: string;
  currency: string;
  noticePeriod: string;
  languages: string[];
  internshipExperienceInMonths: string;
  willingToRelocate: boolean;
  projectSpecialization: string[];
  priorRevitExp: string;
  closeInterestPopup?: boolean;
  closeGraduationPopup?: boolean;
  isDisengaged?: boolean;
  cohortId: string;
}
export type UserCohortPlacementPreferenceResponse = UserCohortPlacementDetails[];
