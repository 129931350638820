import { combineReducers } from "redux";

import { applicationFormSlice } from "./applicationForm";
import { authSlice } from "./auth";
import cohortReducer from "./cohortReducer";
import evaluationDaySlice from "./evaluationDay";
import { flagTrackerSlice } from "./flagTracker";
import formSlice from "./formSlice";
import nodeSlice from "./nodeSlice";
import { placementHubSlice } from "./placementHub";
import { popupSlice } from "./popup";
import queryReducer from "./queries";
import quizSlice from "./quizSlice";
import { streamTextSlice } from "./streamText";
import utilitySlice from "./utilitySlice";

const appReducer = combineReducers({
  auth: authSlice?.reducer,
  popup: popupSlice?.reducer,
  node: nodeSlice,
  form: formSlice,
  formApplication: applicationFormSlice?.reducer,
  cohortInfo: cohortReducer,
  quizV2: quizSlice,
  evaluationDay: evaluationDaySlice,
  placementhub: placementHubSlice?.reducer,
  streamText: streamTextSlice.reducer,
  flagTracker: flagTrackerSlice.reducer,
  queryReducer,
  utility: utilitySlice,
});

const rootReducer: typeof appReducer = (state, action) => {
  if (action.type === "auth/logout") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
