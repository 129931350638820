import { theme } from "@/theme";
import styled from "styled-components";

interface SidebarWrapperPropTypes {
  globalSideBarToggle: boolean;
}
const SidebarWrapper = styled.div<SidebarWrapperPropTypes>`
  max-width: 220px;
  height: 100%;
  background-color: white;
  transition: all 0.4s;
  width: ${({ globalSideBarToggle }) =>
    globalSideBarToggle ? "60px" : "220px"};
  margin-right: ${({ globalSideBarToggle }) =>
    globalSideBarToggle ? "0" : "8px"};
  margin-left: ${({ globalSideBarToggle }) =>
    globalSideBarToggle ? "8px" : "8px"};
  @media (max-width: 768px) {
    position: fixed;
    z-index: 20;
    height: 100%;
    display: ${({ globalSideBarToggle }) =>
      globalSideBarToggle ? "none" : "block"};
    overflow: hidden;
    margin-right: 0;
    margin-left: 0;
    z-index: ${({ globalSideBarToggle }) => (globalSideBarToggle ? 100 : "")};
  }
  @media (max-width: 1080px) {
    position: fixed;
    z-index: 20;
    height: 100%;
    display: ${({ globalSideBarToggle }) =>
      globalSideBarToggle ? "none" : "block"};
    width: ${({ globalSideBarToggle }) =>
      globalSideBarToggle ? "220px" : "0"};
    overflow: hidden;
    margin-right: 0;
    margin-left: 0;
    z-index: ${({ globalSideBarToggle }) => (globalSideBarToggle ? 100 : "")};
  }
`;

interface SidebarMenuPropTypes {
  globalSideBarToggle?: boolean;
  showMenu: boolean;
  children: React.ReactNode;
}
const SidebarMenu = styled.ul<SidebarMenuPropTypes>`
  height: 100%;
  list-style: none;
  padding-top: 16px;
  /* margin-top: 24px; */
  position: fixed;
  z-index: 20;
  height: 100%;
  margin-right: ${({ globalSideBarToggle }) =>
    globalSideBarToggle ? "8px" : "0px"};
  margin-left: ${({ globalSideBarToggle }) =>
    globalSideBarToggle ? "8px" : "0px"};
  @media (max-width: 768px) {
    background-color: white;
    display: ${({ showMenu }) => (showMenu ? "none" : "block")};
    position: fixed;
    z-index: 20;
    height: 100%;
  }
  @media (max-width: 1080px) {
    display: ${({ showMenu }) => (showMenu ? "none" : "block")};
    position: fixed;
    background-color: white;
    overflow-x: hidden;
    z-index: 20;
    height: 100%;
  }
`;

interface SidebarMenuItemPropTypes {
  locked: boolean;
  active: boolean;
}
const SidebarMenuItem = styled.li<SidebarMenuItemPropTypes>`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 4px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 12px;
  padding-right: 12px;
  /* padding-left: 20px;
  padding-right: 20px; */
  align-items: center;
  &:hover {
    background-color: ${({ locked }) => (locked ? "transparent" : "#EFF1F5")};
    border-radius: ${({ locked }) => (locked ? "0px" : "12px")};
  }
  cursor: ${({ locked }) => (locked ? "default" : "pointer")};
  background-color: ${({ locked }) => (locked ? "" : "transparent")};
  border: ${(props) =>
    props.active && !props.locked
      ? "1px solid #9B8AFB"
      : "1px solid transparent"};
  border-radius: ${(props) => (props.active && !props.locked ? "12px" : "0px")};
  box-shadow: ${(props) =>
    props.active && !props.locked
      ? "0px 0px 0px 0px rgba(0, 0, 0, 0.03), 0px 1px 2px 0px rgba(0, 0, 0, 0.03), 0px 3px 3px 0px rgba(0, 0, 0, 0.03), 0px 6px 4px 0px rgba(0, 0, 0, 0.02)"
      : "transparent"};
  color: ${({ locked }) => (locked ? "#999" : "#333")};
`;
export const SidebarMenuMobileItem = styled.li<SidebarMenuItemPropTypes>`
  align-items: center;
  gap: 12px;
  margin-bottom: 4px;
  padding: 12px 16px;
  align-items: center;
  &:hover {
    background-color: ${({ locked }) => (locked ? "transparent" : "#EFF1F5")};
  }
  cursor: ${({ locked }) => (locked ? "default" : "pointer")};
  background-color: ${({ locked, active }) =>
    active ? theme.colors.purple[100] : locked ? "" : "transparent"};
  color: ${({ locked }) => (locked ? "#999" : "#30374F")};
`;

interface SidebarItemTextPropTypes {
  active: boolean;
}
const SidebarItemText = styled.span<SidebarItemTextPropTypes>`
  font-size: 14px;
  font-weight: 500;
  /* margin-left: 12px; */
  color: ${(props) => (props.active ? "#5925DC" : "#30374F")};
`;

interface NewSupportAndSetPropTypes {
  locked: boolean;
  active: boolean;
}
const NewSupportAndSet = styled.li<NewSupportAndSetPropTypes>`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 4px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 20px;
  padding-right: 20px;
  &:hover {
    background-color: ${({ locked }) => (locked ? "transparent" : "#EFF1F5")};
    border-radius: ${({ locked }) => (locked ? "0px" : "12px")};
  }
  cursor: ${({ locked }) => (locked ? "default" : "pointer")};
  background-color: ${({ locked }) => (locked ? "#f1f1f1" : "transparent")};
  border: ${(props) =>
    props.active ? "1px solid #9B8AFB" : "1px solid transparent"};
  border-radius: ${(props) => (props.active ? "12px" : "0px")};
  box-shadow: ${(props) =>
    props.active
      ? "0px 0px 0px 0px rgba(0, 0, 0, 0.03), 0px 1px 2px 0px rgba(0, 0, 0, 0.03), 0px 3px 3px 0px rgba(0, 0, 0, 0.03), 0px 6px 4px 0px rgba(0, 0, 0, 0.02)"
      : "transparent"};
`;

interface CustomSidebarMenuItemPropTypes {
  locked: boolean;
  active: boolean;
}
const CustomSidebarMenuItem = styled.li<CustomSidebarMenuItemPropTypes>`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 4px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
  &:hover {
    background-color: ${({ locked }) => (locked ? "transparent" : "#EFF1F5")};
    border-radius: ${({ locked }) => (locked ? "0px" : "12px")};
  }
  cursor: ${({ locked }) => (locked ? "default" : "pointer")};
  background-color: ${({ locked }) => (locked ? "#f1f1f1" : "transparent")};
  border: ${(props) =>
    props.active ? "1px solid #9B8AFB" : "1px solid transparent"};
  border-radius: ${(props) => (props.active ? "12px" : "0px")};
  box-shadow: ${(props) =>
    props.active
      ? "0px 0px 0px 0px rgba(0, 0, 0, 0.03), 0px 1px 2px 0px rgba(0, 0, 0, 0.03), 0px 3px 3px 0px rgba(0, 0, 0, 0.03), 0px 6px 4px 0px rgba(0, 0, 0, 0.02)"
      : "transparent"};
`;

interface ChannelPlugWrapperPropTypes {
  showMenu: boolean;
}
const ChannelPlugWrapper = styled.div<ChannelPlugWrapperPropTypes>`
  color: #667085;
  font-size: 12px;
  font-weight: 500;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 8px;
  display: ${(props) => (props.showMenu ? "none" : "flex")};
`;

const ChannelPlugWrapperMobile = styled.div`
  color: #667085;
  font-size: 12px;
  font-weight: 500;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 8px;
  display: flex;
  margin-top: 28px;
`;

const NewSupportSetWrapper = styled.div`
  width: 100%;
  position: absolute;
  margin-bottom: 22px;
  bottom: 0;
`;

const ChanneMainWrapper = styled.div`
  margin-top: 28px;
`;

const LockedItemImageContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: center;
  transition: all 0.4s;
`;

const GlobalNavbarMobileIconsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;
export {
  SidebarWrapper,
  SidebarMenu,
  SidebarMenuItem,
  SidebarItemText,
  CustomSidebarMenuItem,
  NewSupportAndSet,
  ChannelPlugWrapper,
  ChannelPlugWrapperMobile,
  NewSupportSetWrapper,
  ChanneMainWrapper,
  LockedItemImageContainer,
  GlobalNavbarMobileIconsWrapper,
};
