import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

import { FeedbackQuestionsType, FeedbackStage, NAMESPACE_TYPE } from "@/utils/types.enums";
import { getTransformedReport } from "@/utils/utils";

import { baseQueryApiClient } from "./BaseQueryApiClient";
import { Cohort, CohortDataApiRequest, CohortUserData, NextCohortDateApiResponse } from "./type";
import {
  InterviewItem,
  BookSlotArguments,
  SlotsResponse,
  SlotArguments,
  FeedbacksResponse,
  FeedbackArguments,
  FilterQuesResponse,
  FeedbackQuesArguments,
  FilteredQuesResponse,
  FeedbackDetailsResponse,
  FeedbackDetailsArguments,
  SectionResponse,
  FeedbackFormArguments,
  MarkAbsentArguments,
  SelectIgArguments,
  FeedbackReportResponse,
  FeedbackReportTransform,
} from "./types/evalutaionApi.type";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

// const {query} = useRouter();
interface QueryArguments {
  quizId: string;
  formId: string;
  cohortId: string;
  responseId: string;
  responseEntries: any[];
  namespace?: NAMESPACE_TYPE;
  isPreview?: boolean;
}

// Define the type for the return value
interface QueryResponse {
  url: string;
  method: string;
  body: any;
  responseHandler: (response: Response) => Promise<string>;
}

export const getCourseContent = baseQueryApiClient.injectEndpoints({
  // reducerPath: "getCourseContent",
  // baseQuery: fetchBaseQuery({
  //   baseUrl: `${process.env.NEXT_PUBLIC_PROXY_2}`,
  //   prepareHeaders: (headers) => {
  //     headers.set("Content-Type", "application/json");
  //     headers.set("x-auth-token", `${Cookies.get("token")}`);
  //     headers.set(
  //       "x-auth-override",
  //       process.env.NEXT_PUBLIC_ENABLE_OVERRIDE_USER
  //     );
  //     return headers;
  //   },
  // }),
  // tagTypes: [
  //   "STATUS-COMPLETE",
  //   "ELECTIVE-SELECTION",
  //   "UPLOAD-ASSIGNMENT",
  //   "STUDIO-SELECTION",
  //   "REFETCH-TIPS",
  // ],
  endpoints: (builder) => ({
    getCohort: builder.query<Cohort, string>({
      query: (cohortId) => `/v1/client/lms/user/${cohortId}/cohort`,
    }),
    getTreeIndexData: builder.query({
      query: ({ cohortId, isPreview, namespace }) =>
        `/v2/client/lms/${cohortId}/cohort-node-data/index${isPreview ? `/preview` : ""}?namespace=${namespace}`,
    }),
    getCareerContent: builder.query({
      query: ({ cohortId, namespace, nodeId, isPreview }) =>
        `/v2/client/lms/${cohortId}/content${isPreview ? `/preview` : ""}?namespace=${namespace}&node-id=${nodeId}`,
      transformResponse: (response: any) => {
        if (response?.children) {
          response.children = response.children.sort((a: any, b: any) => a?.rank - b?.rank);
        }
        return response;
      },
    }),
    getEvaluationDayContent: builder.query({
      query: ({ cohortId, namespace, nodeId, role, isPreview }) =>
        `/v2/client/lms/${cohortId}/content${isPreview ? "/preview" : ""}?namespace=${namespace}&node-id=${nodeId}&role=${role}`,
      transformResponse: (response: any) => {
        if (response?.children) {
          response.children = response.children.sort((a: any, b: any) => a?.rank - b?.rank);
        }
        return response;
      },
    }),
    getTopicListingData: builder.query({
      query: (payload) =>
        `/v2/client/lms/${payload.cohortId}/cohort-node-data${
          payload.isPreview ? "/preview" : ""
        }?depth=2&namespace=${payload.namespace}&nodeId=${payload.nodeId}`,
    }),
    getTopicContentData: builder.query({
      query: ({ cohortId, namespace = NAMESPACE_TYPE.LEARNING, nodeId, isPreview }) =>
        `/v2/client/lms/${cohortId}/cohort-node-data${
          isPreview ? "/preview" : ""
        }?depth=10&namespace=${namespace}&nodeId=${nodeId}`,
    }),
    getAggSkillLogs: builder.query({
      query: (cohortId) => `/v1/client/lms/product/${cohortId}/skill-logs/agg`,
    }),
    getNodesState: builder.query({
      query: ({ cohortId, namespace, isPreview }) =>
        `/v2/client/lms/${cohortId}/nodes/states?nodeId=ROOT&namespace=${namespace}&isPreview=${isPreview}`,
      providesTags: [
        "STATUS-COMPLETE",
        "UPLOAD-ASSIGNMENT",
        "STAGEREVIEW-UPDATE",
        "TASK-UPDATE",
        "UPLOAD-DOCUMENT",
      ],
    }),
    getSkillMapping: builder.query({
      query: (cohortId) => `v1/client/public/lms/skills?cohort-id=${cohortId}`,
    }),
    updateCompleteStatus: builder.mutation({
      query(payload) {
        return {
          url: `/v2/client/lms/${payload.cohortId}/nodes/states?namespace=${payload?.namespace}&nodeId=${payload.nodeId}`,
          method: "PUT",
        };
      },
      invalidatesTags: ["STATUS-COMPLETE"],
    }),
    selectElective: builder.mutation({
      query(payload) {
        return {
          url: `/v2/client/lms/user/${payload.cohortId}/cohort-user-data/select-elective`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["ELECTIVE-SELECTION"],
    }),
    selectStudio: builder.mutation({
      query(payload) {
        return {
          url: `/v2/client/lms/user/${payload.cohortId}/cohort-user-data/select-elective-specialisation`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["STUDIO-SELECTION"],
    }),
    markAbsent: builder.mutation<boolean, MarkAbsentArguments>({
      query({ cohortId, userId }) {
        return {
          url: `/v2/client/lms/${cohortId}/mark-absent-evaluation?userId=${userId}`,
          method: "PUT",
        };
      },
      invalidatesTags: ["MARK-ABSENT", "LEARNER-FEEDBACK-DETAIL"],
    }),
    selectIg: builder.mutation<boolean, SelectIgArguments>({
      query({ cohortId, payload }) {
        return {
          url: `/v2/client/lms/${cohortId}/mark-primary-mentor`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["PRIMARY-IG-SELECTION"],
    }),
    getFormPage: builder.query({
      query: ({ formId, productId }) => {
        return {
          url: `/v1/client/form/page/${formId}`,
          headers: { "x-product-id": productId },
        };
      },
    }),
    postFormResponse: builder.mutation({
      query: ({ cohortId, formId }) => {
        return {
          url: `/v1/client/form/response/${formId}`,
          body: { productId: cohortId },
          method: "POST",
          headers: { "x-product-id": cohortId },
        };
      },
    }),
    postFormResponseOption: builder.mutation({
      query: ({ cohortId, entries, questionId, responseId, formId }) => {
        return {
          url: `/v1/client/form/response/check/${formId}?response-id=${responseId}`,
          body: { entries, questionId },
          method: "PUT",
          headers: { "x-product-id": cohortId },
        };
      },
    }),
    getFormResponseData: builder.query({
      query: ({ responseId, formId, cohortId }) => {
        return {
          url: `/v1/client/form/response/report/${formId}?response-id=${responseId}`,
          headers: { "x-product-id": cohortId },
        };
      },
    }),
    postAssignmentGetBucketUrl: builder.mutation({
      query: ({ query, body }) => {
        const { cohortId } = query;
        return {
          url: `v1/client/lms/product/${cohortId}/report/submit/upload/url`,
          body: body,
          method: "POST",
        };
      },
    }),
    postToolTipBucketUrl: builder.mutation({
      query: ({ body }) => {
        return {
          url: `v1/client/mentor/report/submit/upload/url`,
          body: body,
          method: "POST",
        };
      },
    }),
    postAssignmentPostBucketUrl: builder.mutation({
      query: (postData) => {
        const { params, body } = postData;
        const { cohortId } = params;
        return {
          url: `v2/client/lms/${cohortId}/report/submit/upload`,
          body: body,
          method: "POST",
        };
      },
      invalidatesTags: ["UPLOAD-ASSIGNMENT"],
    }),
    getOrderData: builder.query({
      query: ({ orderId }) => {
        return {
          url: `/v1/client/oms/${orderId}/`,
        };
      },
    }),
    getEmiStatus: builder.mutation({
      query: ({ orderId, planId }) => {
        return {
          url: `/v1/client/oms/${orderId}/order/plan/emi/status`,
          body: { planId },
          method: "POST",
        };
      },
    }),
    getPaymentPlan: builder.query({
      query: ({ orderId }) => {
        return {
          url: `/v1/client/oms/${orderId}/payment-plan`,
        };
      },
    }),
    postEmiFormData: builder.mutation({
      query: ({ orderId, formId, responseEntries, responseId, planId, meta }) => {
        return {
          url: `v1/client/oms/${orderId}/order/plan/emi/form/${formId}`,
          body: { responseEntries, meta, planId, responseId },
          method: "POST",
        };
      },
    }),
    getEmiForm: builder.query({
      query: ({ orderId, formId }) => {
        return {
          url: `v1/client/oms/${orderId}/order/emi/form/${formId}`,
        };
      },
    }),
    getEmiFormResponse: builder.mutation({
      query: ({ orderId, formId, responseId, planId }) => {
        return {
          url: `v1/client/oms/${orderId}/order/plan/emi/form-response/${formId}?response-id=${responseId}`,
          method: "POST",
          body: { planId },
        };
      },
    }),
    postEmiDocument: builder.mutation({
      query: ({ orderId, data }) => {
        return {
          url: `v1/client/oms/${orderId}/order/emi/document`,
          method: "PUT",
          body: data,
          prepareHeaders: (headers: any) => {
            headers.set("Content-Type", "multipart/form-data");
            return headers;
          },
          formData: true,
        };
      },
    }),
    postFinalFormData: builder.mutation({
      query: ({ orderId, planId }) => {
        return {
          url: `v1/client/oms/${orderId}/order/plan/emi/submit`,
          method: "POST",
          body: { planId },
        };
      },
    }),
    createInstallmentPaymentPreview: builder.mutation({
      query: ({ orderId, paymentPlanId, client }) => {
        return {
          url: `v1/client/oms/${orderId}/order/payment-plan-review`,
          method: "POST",
          body: { paymentPlanId, client },
        };
      },
    }),
    getOrders: builder.query({
      query: () => {
        return {
          url: `v1/client/user/orders`,
        };
      },
    }),
    submitQuiz: builder.mutation({
      query: ({ responseId, formId, cohortId, nodeId, namespace, forceSubmit }) => {
        return {
          url: `v2/client/lms/${cohortId}/quiz/completed?node-id=${nodeId}&namespace=${namespace}&forceSubmit=${forceSubmit}`,
          method: "POST",
          body: { responseId, formId },
        };
      },
      invalidatesTags: ["STATUS-COMPLETE"],
    }),
    getMentorPage: builder.query({
      query: () => {
        return {
          url: `/v1/client/mentor/page`,
          method: "GET",
        };
      },
      keepUnusedDataFor: 0,
    }),
    getCalenderEvents: builder.query({
      query: (cohortId) => {
        return {
          url: `/v2/client/lms/${cohortId}/calender-events`,
          method: "GET",
        };
      },
    }),
    getLearnerDashboard: builder.query({
      query: ({ cohortId }) => {
        return {
          url: `/v2/client/lms/${cohortId}/dashboardV2`,
          method: "GET",
        };
      },
    }),
    getNudges: builder.query({
      query: ({ cohortId }) => {
        return {
          url: `/v2/client/lms/${cohortId}/nudges`,
          method: "GET",
        };
      },
    }),
    getLearnerPendingTask: builder.query({
      query: ({ cohortId }) => {
        return {
          url: `/v2/client/lms/${cohortId}/dashboardV2/pending-tasks`,
          method: "GET",
        };
      },
    }),
    getLearnerCalenderData: builder.query({
      query: ({ cohortId, weekStart, weekEnd }) => {
        return {
          url: `/v2/client/lms/${cohortId}/dashboardV2/calender-data?weekStart=${weekStart}&weekEnd=${weekEnd}`,
        };
      },
    }),
    getMentorDashboard: builder.query({
      query: ({ cohortId }) => {
        return {
          url: `/v1/client/mentor/dashboard?cohortId=${cohortId}`,
          method: "GET",
        };
      },
    }),
    getLeadMentorDashboard: builder.query({
      query: ({ cohortId }) => {
        return {
          url: `/v1/client/mentor/lead/${cohortId}/dashboard/fold-1`,
          method: "GET",
        };
      },
    }),

    getCalenderData: builder.mutation({
      query: ({ cohortId, startDate, endDate, isLeadMentor }) => {
        if (isLeadMentor)
          return {
            url: `/v1/client/mentor/lm/calendar?startDate=${startDate}&endDate=${endDate}`,
          };
        return {
          url: `/v2/client/lms/${cohortId}/calenderData`,
          method: "POST",
          body: { weekStart: startDate, weekEnd: endDate },
        };
      },
    }),
    TasksData: builder.query({
      query: ({ cohortId }) => {
        return {
          url: `/v2/client/lms/${cohortId}/tasks`,
          method: "get",
        };
      },
    }),
    getPlacementEligibility: builder.query({
      query: () => `/v1/client/hiring/user/placements/check-eligibility`,
    }),
    getSessionStatsDashboard: builder.query({
      query: ({ cohortId }) => `/v1/client/mentor/lm/session-stats/${cohortId}`,
    }),
    AddTip: builder.mutation({
      query: ({ cohortId, attachments, comment, liveSessionId }) => {
        return {
          url: `/v1/client/mentor/lead/${cohortId}/dashboard/tip`,
          method: "POST",
          body: {
            liveSessionId: liveSessionId,
            content: comment,
            attachments: attachments,
          },
        };
      },
      invalidatesTags: ["REFETCH-TIPS"],
    }),
    getTips: builder.query({
      query: (cohortId) => `/v1/client/mentor/lead/${cohortId}/dashboard/tip?pageNo=0`,
      providesTags: ["REFETCH-TIPS"],
    }),

    // showCoachmarks api

    getCoachmarks: builder.query({
      query: (cohortId) => `/v2/client/lms/${cohortId}/coachMarks`,
      providesTags: ["REFETCH-COACHMARKS"],
    }),

    updateCoachmarks: builder.mutation({
      query: ({ cohortId, payload }) => {
        return {
          url: `/v2/client/lms/${cohortId}/coachMarks`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: ["REFETCH-COACHMARKS"],
    }),
    getQuizV2Data: builder.mutation({
      query: ({
        quizId,
        formId,
        cohortId,
        retake = false,
        namespace = "LEARNING",
        isPreview = false,
      }) => {
        return {
          url: `v2/client/lms/${cohortId}/start-quiz/${namespace}`,
          method: "POST",
          body: { quizId, formId, isRetake: retake, isPreview },
        };
      },
    }),
    postQuizResponse: builder.mutation<QueryResponse, QueryArguments>({
      query: ({
        quizId,
        formId,
        cohortId,
        responseId,
        responseEntries,
        namespace = NAMESPACE_TYPE.LEARNING,
        isPreview = false,
      }) => {
        return {
          url: `v1/client/form/responses/check`,
          method: "PUT",
          body: {
            nodeId: quizId,
            formId,
            cohortId,
            responseId,
            responseEntries,
            namespace,
            isPreview,
          },
          responseHandler: (response) => response.text(),
        };
      },
    }),
    startPolling: builder.mutation({
      query: ({ quizId, cohortId }) => {
        return {
          url: `v2/client/lms/${cohortId}/activity-poll?activityId=${quizId}&cohortId=${cohortId}`,
          method: "PUT",
          // body: { activityId: quizId, cohortId: cohortId },
        };
      },
    }),
    stopActivity: builder.mutation({
      query: ({ quizId, cohortId }) => {
        return {
          url: `v2/client/lms/${cohortId}/inactivity-poll?activityId=${quizId}&cohortId=${cohortId}`,
          method: "PUT",
          // body: { activityId: quizId, cohortId: cohortId },
        };
      },
    }),
    forceSubmitQuiz: builder.mutation({
      query: ({ responseId, formId, cohortId, nodeId, namespace, forceSubmit }) => {
        return {
          url: `v2/client/lms/${cohortId}/v2/quiz/completed?node-id=${nodeId}&namespace=${namespace}&forceSubmit=${forceSubmit}`,
          method: "POST",
          body: { responseId, formId },
        };
      },
    }),
    getQuizV2Details: builder.query({
      query: ({
        responseId,
        formId,
        cohortId,
        quizId,
        namespace,
        forceSubmit,
        isPreview = false,
      }) => {
        return {
          url: `v2/client/lms/${cohortId}/fetch-quiz-details/${namespace}?quizId=${quizId}&formId=${formId}&isPreview=${isPreview}`,
          method: "GET",
        };
      },
    }),

    bookSlot: builder.mutation<boolean, BookSlotArguments>({
      query: (slotData) => {
        return {
          url: `v2/client/lms/${slotData.cohortId}/slot`,
          method: "POST",
          body: slotData.slotInfo,
        };
      },
      invalidatesTags: ["STATUS-COMPLETE"],
    }),
    getSlots: builder.query<SlotsResponse, SlotArguments>({
      query: ({ cohortId }) => {
        return {
          url: `v2/client/lms/${cohortId}/slots`,
        };
      },
      transformResponse: (response: any) => {
        const extractTime = (dateString, timeZone = "Asia/Kolkata") => {
          const date = dayjs(dateString).tz(timeZone);
          return date.format("hh:mm A");
        };
        for (const key in response) {
          response[key] = response[key].map((e) => {
            return {
              slotId: e.slotId,
              startTime: extractTime(e.startDate),
              available: e.status === "ACTIVE",
            };
          });
        }
        return response;
      },
    }),
    getFeedbacks: builder.query<FeedbacksResponse, FeedbackArguments>({
      query: ({ cohortId, mentorId }) => {
        return {
          url: `/v1/client/mentor/${cohortId}/feedback-details?mentorId=${mentorId}`,
        };
      },
      transformResponse: (response: InterviewItem[]) => {
        const modifiedResponse = {
          pending: response?.filter(
            (e) =>
              e?.feedbackStatus !== FeedbackStage.FEEDBACK_COMPLETED &&
              e?.feedbackStatus !== FeedbackStage.MISSED
          ),
          completed: response?.filter(
            (e) =>
              e?.feedbackStatus === FeedbackStage.FEEDBACK_COMPLETED ||
              e?.feedbackStatus === FeedbackStage.MISSED
          ),
          countTotal: response?.length,
        };
        return modifiedResponse;
      },
      providesTags: ["MARK-ABSENT", "FEEDBACK-SUBMISSION"],
    }),
    getFilterQuestions: builder.query<FilterQuesResponse, FeedbackQuesArguments>({
      query: ({ cohortId, userId, nodeId, reAssess }) => {
        return {
          url: `/v1/client/mentor/${cohortId}/get-filter-questions?userId=${userId}&nodeId=${nodeId}&reAssess=${reAssess}`,
        };
      },
    }),
    getFilteredQuestions: builder.query<FilteredQuesResponse, FeedbackQuesArguments>({
      query: ({ cohortId, userId, nodeId, reAssess }) => {
        return {
          url: `/v1/client/mentor/${cohortId}/get-filtered-evaluation-form?userId=${userId}&nodeId=${nodeId}&reAssess=${reAssess}`,
        };
      },
      providesTags: ["FILTERED-QUESTIONS"],
    }),
    getFeedbackDetails: builder.query<FeedbackDetailsResponse, FeedbackDetailsArguments>({
      query: ({ cohortId, userId }) => {
        return {
          url: `/v2/client/lms/${cohortId}/learner-feedback-detail?userId=${userId}`,
        };
      },
      providesTags: ["PRIMARY-IG-SELECTION", "LEARNER-FEEDBACK-DETAIL"],
    }),
    getEvaluationDayLiveSession: builder.query({
      query: ({ cohortId }) => {
        return {
          url: `/v2/client/lms/${cohortId}/evaluation-day-live-session/`,
        };
      },
    }),
    postSectionResponse: builder.mutation<boolean, SectionResponse>({
      query: ({ body, cohortId }) => {
        return {
          url: `/v1/client/mentor/${cohortId}/bulk-register-response`,
          body: body,
          method: "POST",
        };
      },
    }),
    submitFeedbackForm: builder.mutation<boolean, FeedbackFormArguments>({
      query: ({ body, cohortId }) => {
        return {
          url: `/v1/client/mentor/${cohortId}/assess-evaluation-form`,
          body: body,
          method: "POST",
        };
      },
      invalidatesTags: ["LEARNER-FEEDBACK-DETAIL", "FEEDBACK-SUBMISSION"],
    }),
    getFeedbackReport: builder.query<FeedbackReportTransform, { token: string }>({
      query: ({ token }) => {
        return {
          url: `/v1/client/public/lms/get-evaluation-report-by-token?token=${token}`,
        };
      },
      transformResponse: (response: FeedbackReportResponse): FeedbackReportTransform => {
        return getTransformedReport(response);
      },
    }),
    getFeedbackReportIg: builder.query<
      FeedbackReportTransform,
      { userId: number; nodeId: string; cohortId: string }
    >({
      query: ({ userId, nodeId, cohortId }) => {
        return {
          url: `v1/client/mentor/${cohortId}/get-evaluation-report?userId=${userId}&nodeId=${nodeId}`,
        };
      },
      transformResponse: (response: FeedbackReportResponse): FeedbackReportTransform => {
        return getTransformedReport(response);
      },
    }),
    getFeedbackReportLearn: builder.query<
      FeedbackReportTransform,
      { userId: number; nodeId: string; cohortId: string }
    >({
      query: ({ userId, nodeId, cohortId }) => {
        return {
          url: `v2/client/lms/${cohortId}/get-evaluation-report?userId=${userId}&nodeId=${nodeId}`,
        };
      },
      transformResponse: (response: FeedbackReportResponse): FeedbackReportTransform => {
        return getTransformedReport(response);
      },
    }),
    postDocumentPostBucketUrl: builder.mutation({
      query: (postData) => {
        const { query, body, namespace } = postData;
        const { cohortId } = query;
        return {
          url: `v2/client/lms/${cohortId}/submit-report/${namespace}`,
          body: body,
          method: "POST",
        };
      },
      invalidatesTags: ["UPLOAD-DOCUMENT"],
    }),
    postDocumentGetBucketUrl: builder.mutation({
      query: ({ query, body }) => {
        const { cohortId } = query;
        return {
          url: `v1/client/lms/product/${cohortId}/report/submit/upload/url`,
          body: body,
          method: "POST",
        };
      },
    }),
    getNextCohortDate: builder.query<NextCohortDateApiResponse, void>({
      query: () => ({
        url: `v1/client/public/lms/upcoming-latest-cohorts`,
      }),
    }),
    markOngoingState: builder.mutation({
      query: ({ nodeId, namespace, cohortId }) => {
        return {
          url: `v2/client/lms/${cohortId}/mark-node-ongoing/${namespace}`,
          method: "POST",
          body: { nodeId },
        };
      },
    }),
    getUserCohortData: builder.query<CohortUserData, CohortDataApiRequest>({
      query: ({ cohortId }) => ({
        url: `v1/client/lms/user/${cohortId}/cohort-user-data`,
      }),
    }),
  }),
});

export const {
  useGetTreeIndexDataQuery,
  useGetCareerContentQuery,
  useGetFormPageQuery,
  usePostFormResponseMutation,
  usePostFormResponseOptionMutation,
  useGetFormResponseDataQuery,
  useGetTopicListingDataQuery,
  useGetTopicContentDataQuery,
  useGetAggSkillLogsQuery,
  useGetNodesStateQuery,
  useGetSkillMappingQuery,
  useUpdateCompleteStatusMutation,
  useSelectElectiveMutation,
  useSelectStudioMutation,
  usePostAssignmentGetBucketUrlMutation,
  usePostToolTipBucketUrlMutation,
  usePostAssignmentPostBucketUrlMutation,
  useGetOrderDataQuery,
  useGetEmiStatusMutation,
  useGetPaymentPlanQuery,
  usePostEmiFormDataMutation,
  useGetEmiFormQuery,
  useGetEmiFormResponseMutation,
  usePostEmiDocumentMutation,
  usePostFinalFormDataMutation,
  useCreateInstallmentPaymentPreviewMutation,
  useGetOrdersQuery,
  useGetCohortQuery,
  useSubmitQuizMutation,
  useGetMentorPageQuery,
  useGetCalenderEventsQuery,
  useGetLearnerDashboardQuery,
  useGetLearnerPendingTaskQuery,
  useGetLearnerCalenderDataQuery,
  useGetMentorDashboardQuery,
  useGetLeadMentorDashboardQuery,
  useGetCalenderDataMutation,
  useTasksDataQuery,
  useGetPlacementEligibilityQuery,
  useAddTipMutation,
  useGetTipsQuery,
  useGetSessionStatsDashboardQuery,
  useGetCoachmarksQuery,
  useUpdateCoachmarksMutation,
  useGetNudgesQuery,
  useGetQuizV2DataMutation,
  usePostQuizResponseMutation,
  useStartPollingMutation,
  useStopActivityMutation,
  useForceSubmitQuizMutation,
  useGetQuizV2DetailsQuery,
  useGetEvaluationDayContentQuery,
  usePostDocumentGetBucketUrlMutation,
  usePostDocumentPostBucketUrlMutation,
  useGetSlotsQuery,
  useGetFeedbacksQuery,
  useGetEvaluationDayLiveSessionQuery,
  useBookSlotMutation,
  useGetNextCohortDateQuery,
  useMarkOngoingStateMutation,
  useGetUserCohortDataQuery,
  useMarkAbsentMutation,
  useSelectIgMutation,
  useGetFeedbackDetailsQuery,
  useGetFilterQuestionsQuery,
  usePostSectionResponseMutation,
  useGetFilteredQuestionsQuery,
  useSubmitFeedbackFormMutation,
  useGetFeedbackReportQuery,
  useGetFeedbackReportIgQuery,
  useGetFeedbackReportLearnQuery,
} = getCourseContent;

export const skillSelector = (param: string) =>
  getCourseContent.endpoints.getSkillMapping.select(param);
