import { theme } from "@/theme";
import styled from "styled-components";

export const DotStyle = styled.div`
  width: 4px;
  height: 4px;
  margin-right: ${theme.spacing(3)};
  margin-left: ${theme.spacing(3)};
  border-radius: 50%;
  background-color: ${({ color }) => color || theme.colors.primary[400]};
`;
