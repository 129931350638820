import PrimaryButton from "@/design-system-v2/Button/PrimaryButton";
import FlexContainer from "@/design-system-v2/FlexContainer";
import Typography from "@/design-system-v2/Typography";
import useMediaQuery from "@/design-system/useMediaQuery";
import { theme } from "@/theme";
import { ReactNode } from "react";

interface BannerProps {
  title: string;
  ctaLabel: string;
  bannerIcon: ReactNode;
  ctaIcon: ReactNode;
  ctaCallback?: () => void;
}

const Banner = ({
  title,
  ctaLabel,
  ctaIcon,
  bannerIcon,
  ctaCallback,
}: BannerProps) => {
  const isMobileScreen = useMediaQuery(`(max-width:${theme.breakpoints.md})`);

  return (
    <FlexContainer
      background={theme.colors.warning[300]}
      mb={theme.spacing(8)}
      borderRadius={theme.radius(3)}
      px={20}
      py={isMobileScreen ? 20 : 12}
      gap={"12px"}
      flexDirection={isMobileScreen ? "column" : "row"}
      justifyContent={"space-between"}
      alignItems={isMobileScreen ? "flex-start" : "center"}
      maxWidth={"1000px"}
    >
      <FlexContainer alignItems={"center"} gap={theme.spacing(2)}>
        {bannerIcon}
        <Typography
          varient="textmd"
          weightVarient="semibold"
          color={theme.colors.base.black}
        >
          {title}
        </Typography>
      </FlexContainer>
      <PrimaryButton
        label={ctaLabel}
        size={"xs"}
        color={"neutral"}
        mt={isMobileScreen ? theme.spacing(3) : theme.spacing(0)}
        suffixIcon={ctaIcon}
        onClick={ctaCallback}
      />
    </FlexContainer>
  );
};

export default Banner;
