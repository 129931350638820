import { FC, HTMLAttributes, ReactElement } from "react";

import Box from "@/design-system/Box";
import Icon from "@/design-system/Icon";
import { IconVarient } from "@/design-system/Icon/types";

import { theme } from "@/theme";

import { badgeStyleVarients, sizeVarients } from "./theme";

// text size, responsive,

export interface BadgeProps extends HTMLAttributes<HTMLDivElement> {
  text?: string;
  size?: "sm" | "md" | "lg";
  badgeStyle?: "light" | "dark" | "outline" | "bold" | "invert" | "whiteBg" | "grayCool";
  colorVarient?:
    | "green"
    | "moss"
    | "red"
    | "orange"
    | "yellow"
    | "gray"
    | "black"
    | "blue"
    | "indigo"
    | "primary"
    | "purple"
    | "transparent"
    | "grayCool"
    | "warning";
  leadingNumber?: string;
  leadingIcon?: string;
  leadingType?: "line" | "solid";
  leadingIconSize?: number;
  trailingIconSize?: number;
  trailingIcon?: string;
  trailingType?: "line" | "solid";
  dotLeading?: boolean;
  icon?: string;
  height?: string;
  trailingChild?: ReactElement | null;
}

const Badge: FC<BadgeProps> = ({
  text,
  dotLeading = false,
  leadingIcon = "",
  leadingType = "line",
  leadingIconSize = 12,
  trailingIconSize = 12,
  trailingIcon = "",
  trailingType = "solid",
  badgeStyle = "dark",
  size = "sm",
  height,
  leadingNumber,
  colorVarient = "primary",
  trailingChild = null,
  ...rest
}) => {
  const { dotColor, bgColor, textColor, borderColor } =
    badgeStyleVarients[badgeStyle][colorVarient];

  const { padding, borderRadius, fontSize, fontWeight } = sizeVarients[size];
  return (
    <Box
      style={{ alignItems: "center" }}
      p={padding}
      bg={bgColor}
      height={height ? height : "unset"}
      display={"inline-flex"}
      alignItems={"center"}
      borderRadius={borderRadius}
      border={
        badgeStyle === "outline" || borderColor
          ? `1px solid ${borderColor}`
          : "1px solid transparent"
      }
      {...rest}
    >
      {dotLeading && (
        <Box
          width={6}
          height={6}
          borderRadius="50%"
          bg={dotColor || theme.colors.purple[200]}
          mr={"4px"}
        />
      )}
      {leadingIcon && (
        <Box mr={"4px"} display={"flex"}>
          <Icon
            size={leadingIconSize}
            color={dotColor}
            type={leadingIcon}
            varient={leadingType ? IconVarient[leadingType] : IconVarient.line}
          />
        </Box>
      )}
      {leadingNumber && (
        <Box fontWeight={600} fontSize={fontSize} mr={theme.spacing(1)} color={textColor}>
          {leadingNumber}
        </Box>
      )}
      <Box color={textColor} textAlign={"center"} fontSize={fontSize} fontWeight={fontWeight}>
        {text}
      </Box>
      {trailingIcon && (
        <Box ml={"4px"} display={"flex"}>
          <Icon
            size={trailingIconSize}
            color={dotColor}
            type={trailingIcon}
            varient={trailingType ? IconVarient[trailingType] : IconVarient.solid}
          />
        </Box>
      )}
      {trailingChild && (
        <Box display={"flex"} ml={theme.spacing(1)}>
          {trailingChild}
        </Box>
      )}
    </Box>
  );
};

export default Badge;
