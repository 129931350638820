import dayjs from "dayjs";
import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo, useState } from "react";

import {
  useGetAllCohortPlacementDetailsQuery,
  useGetBannerTypeQuery,
} from "@/client/placementHubClient";
import { COHORT_TIMELINE_KEY } from "@/client/type";

import { getCohortTimelineDate } from "@/utils/utils";

import { BannerType } from "@/components/PlacementHub/types";

import { useAppSelector } from "./hooks";

export enum PlacementHubParams {
  Home = "",
  Job = "/job",
  Jobs = "/jobs",
  Application = "/application",
  Applications = "/applications",
  InterestForm = "/interest-form",
  InterestFormView = "/interest-form/view",
  InterestFormEdit = "/interest-form/edit",
  SelfPlaced = "/self-placed",
}

const defaultPlacementDetails = {
  interestedInPlacements: false,
  countryPreferences: [],
  locationPreferences: [],
  currentCtc: "",
  expectedCtc: "",
  currency: "",
  noticePeriod: "",
  languages: [],
  internshipExperienceInMonths: "",
  willingToRelocate: false,
  projectSpecialization: [],
  priorRevitExp: "",
  closeInterestPopup: false,
  closeGraduationPopup: false,
  isDisengaged: false,
};

const usePlacementHub = () => {
  const router = useRouter();
  const { pathname, query } = router;
  const { cohortId } = query || {};
  const { cohortUserDataInfo } = useAppSelector((state) => state.rootReducer.cohortInfo);
  const [placementHubCohortId, setPlacementHubCohortId] = useState(null);

  const { data: allCohortPlacementDetails, isSuccess: isAllCohortPlacementSuccess } =
    useGetAllCohortPlacementDetailsQuery();

  const { data: bannerDataResponse, isLoading: isBannerLoading } = useGetBannerTypeQuery(
    { cohortId: cohortId as string },
    { skip: !cohortId }
  );

  useEffect(() => {
    if (cohortId && cohortUserDataInfo && bannerDataResponse) {
      handleRedirectionAsPerCohortPlacementState(cohortId);
    }
  }, [cohortId, cohortUserDataInfo, bannerDataResponse]);

  useEffect(() => {
    if (cohortUserDataInfo) {
      let latestPlacementStartedCohort = [];
      const placementStartedCohorts = cohortUserDataInfo?.filter((cohortData) =>
        cohortData?.cohort?.timelines.find(
          (timeline) => timeline?.key === COHORT_TIMELINE_KEY.PLACEMENT_FORM_DATE
        )
      );
      if (placementStartedCohorts?.length > 0) {
        latestPlacementStartedCohort = placementStartedCohorts.sort((a, b) => {
          const timeA = getCohortTimelineDate(a.cohort, COHORT_TIMELINE_KEY.PLACEMENT_FORM_DATE);
          const timeB = getCohortTimelineDate(b.cohort, COHORT_TIMELINE_KEY.PLACEMENT_FORM_DATE);
          return timeA - timeB;
        });
      }
      for (const cohortData of latestPlacementStartedCohort) {
        const placementDetail =
          allCohortPlacementDetails?.find(
            (item) => item.cohortId === cohortData?.cohort?.productId
          ) || defaultPlacementDetails;
        const { closeInterestPopup, interestedInPlacements } = placementDetail;
        if (!(interestedInPlacements === false && closeInterestPopup)) {
          setPlacementHubCohortId(cohortData?.cohort?.productId);
          return;
        }
      }
      setPlacementHubCohortId("");
    }
  }, [cohortUserDataInfo, isAllCohortPlacementSuccess, allCohortPlacementDetails]);

  const cohortPlacementData = useMemo(() => {
    return (
      allCohortPlacementDetails?.find((item) => item.cohortId === cohortId) ||
      defaultPlacementDetails
    );
  }, [allCohortPlacementDetails, cohortId]);

  const getIsCohortPlacementFormFilled = (cohortId) => {
    return allCohortPlacementDetails?.find((item) => item.cohortId === cohortId)?.noticePeriod;
  };

  const bannerData = useMemo(() => {
    if (bannerDataResponse && !isBannerLoading) {
      return bannerDataResponse;
    }
    return { bannerType: null, hidePlacement: true };
  }, [bannerDataResponse, isBannerLoading]);

  const placementHubRedirection = useCallback(
    (param?: PlacementHubParams, query?: Record<string, string | number>) =>
      (event?: React.MouseEvent<HTMLButtonElement> | undefined) => {
        event?.stopPropagation();
        router.push({
          pathname: `/placement-hub${param ? `${param}` : ""}`,
          query: {
            ...router.query,
            ...(cohortId && { cohortId: cohortId }),
            ...(query && { ...query }),
          },
        });
      },
    [cohortId]
  );

  const getRedirectionPath = (param?: PlacementHubParams) => {
    return `/placement-hub${param ? `${param}` : ""}?cohortId=${cohortId}`;
  };

  const redirectToPlacementHub = useCallback(placementHubRedirection, [cohortId, router]);

  const getPlacementHubRedirectPath = useCallback(getRedirectionPath, [cohortId]);

  const handleRedirectionAsPerCohortPlacementState = useCallback(
    (cohortId) => {
      if (pathname.includes("placement-hub")) {
        const currentCohort = cohortUserDataInfo.find(
          (cohortInfo) => cohortInfo.cohort.productId === cohortId
        )?.cohort;
        const cohortPlacemetFormDate = getCohortTimelineDate(
          currentCohort,
          COHORT_TIMELINE_KEY.PLACEMENT_FORM_DATE
        );
        const cohortPlacemetTime = getCohortTimelineDate(
          currentCohort,
          COHORT_TIMELINE_KEY.PLACEMENT_START_DATE
        );
        const placementStartTime = dayjs(cohortPlacemetTime);
        const placementFormDate = dayjs(cohortPlacemetFormDate);
        const currentDate = dayjs();
        const isPlacementFormAvailable = currentDate.isAfter(placementFormDate);
        const isPlacementStarted = currentDate.isAfter(placementStartTime);
        if (bannerData.bannerType === BannerType.PLACEMENT_FORM_NOT_FILLED) {
          return;
        }
        if (
          !isPlacementFormAvailable ||
          (pathname.includes(PlacementHubParams.InterestForm.replace("/", "")) &&
            isPlacementStarted &&
            getIsCohortPlacementFormFilled(cohortId))
        ) {
          redirectToPlacementHub(PlacementHubParams.Home, { cohortId: cohortId })();
        } else if (
          !getIsCohortPlacementFormFilled(cohortId) &&
          bannerData.bannerType !== BannerType.NOT_ELIGIBLE_FOR_PLACEMENT
        ) {
          redirectToPlacementHub(PlacementHubParams.InterestForm, { cohortId: cohortId })();
        }
      }
    },
    [cohortUserDataInfo, getIsCohortPlacementFormFilled, pathname, redirectToPlacementHub, router]
  );

  return {
    placementHubCohortId,
    redirectToPlacementHub,
    getPlacementHubRedirectPath,
    bannerData,
    cohortPlacementData,
    allCohortPlacementDetails,
    getIsCohortPlacementFormFilled,
    handleRedirectionAsPerCohortPlacementState,
  };
};

export default usePlacementHub;
