import styled, { css } from "styled-components";
import {
  background,
  BackgroundProps,
  border,
  BorderProps,
  color,
  ColorProps,
  flexbox,
  FlexboxProps,
  grid,
  GridProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  shadow,
  ShadowProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from "styled-system";

import { CSSProperties, HTMLAttributes, ReactNode } from "react";

export interface BoxProps
  extends LayoutProps,
    ColorProps,
    TypographyProps,
    SpaceProps,
    GridProps,
    LayoutProps,
    BackgroundProps,
    FlexboxProps,
    BorderProps,
    PositionProps,
    ShadowProps {
  children?: ReactNode;
  style?: CSSProperties;
  id?: string | number;
  className?: string;
  lineBg?: string;
  gap?: string;
  lineWidth?: string;
  positionMd?: PositionProps | undefined;
  placeItems?: string;
  ref?: HTMLAttributes<HTMLDivElement>;
}

const Box = styled.div<BoxProps>`
  ${color}
  ${typography}
    ${space}
    ${grid}
    ${layout}
    ${background}
    ${flexbox}
    ${border}
    ${position}
    ${shadow}
  ${({ gap }) =>
    gap &&
    css`
      gap: ${gap};
    `}
  ${({ placeItems }) =>
    placeItems &&
    css`
      place-items: ${placeItems};
    `} //transition: 0.5s all;
`;
export default Box;
