export const WhiteListedPagesForPerformance = [
  "acm004",
  "acm005",
  "acm006",
  "acm007",
  "acm008",
  "BIM_A_011",
  "BIM_A_012",
  "BIM_A_013",
  "BIM_A_014",
  "BIM_A_015",
  "BIM_A_016",
  "BIM_C_002",
  "BIM_C_003",
  "BIM_C_004",
  "BIM_C_005",
  "BIM_C_006",
];
