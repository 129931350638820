import FlexContainer from "@/design-system-v2/FlexContainer";
import Box from "@/design-system/Box";
import Icon from "@/design-system/Icon";
import { theme } from "@/theme";
import { ReactNode } from "react";
import styled from "styled-components";
import ProfileHeader from "./ProfileHeader";

interface PopupProps {
  heading?: string;
  children: ReactNode;
  onClose: () => void;
  ProfileHeading?: ReactNode;
}

const PopupOverlay = styled(FlexContainer)`
  position: fixed;
  top: 0px;
  left: 0px;
  opacity: 1;
  z-index: 99;
  transition: 0.8s;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  overflow-y: auto;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
`;

const Popup = ({ heading, children, onClose, ProfileHeading }: PopupProps) => {
  return (
    <PopupOverlay>
      <Box width={"90%"} maxWidth={"700px"} maxHeight={"700px"}>
        <ProfileHeader
          text={heading}
          icon={<Icon type={"x"} varient="line" size={14} />}
          iconOnClick={onClose}
          ProfileHeading={ProfileHeading}
          bgColor={theme.colors.gray[100]}
        />
        <Box
          py={{
            _: theme.spacing(6),
            md: theme.spacing(10),
          }}
          px={{
            _: theme.spacing(4),
            md: theme.spacing(6),
          }}
          borderRadius={`0 0 ${theme.radius(4)} ${theme.radius(4)}`}
          background={theme.colors.base.white}
        >
          {children}
        </Box>
      </Box>
    </PopupOverlay>
  );
};

export default Popup;
